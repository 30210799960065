import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import styles from "./ListDrawer.module.scss";
import { Settings, ExpandLess, ExpandMore } from "@mui/icons-material";
import { iconTables } from "assets/icons";

export const InsideDrawerNav = ({
    menu = {},
    route = '',
    level = 0,
    // availableRoutes = [],
    handleClick = () => { },
}) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const location = useLocation();
    const isSelected = location.pathname === `/${route}${menu.route}`;

    if (menu.isGroup) {
        return (
            <>
                <ListItemButton
                    className={styles.itemList}
                    onClick={() => setIsDrawerOpen(!isDrawerOpen)} sx={{ pl: 3 + level }}
                >
                    <ListItemIcon>
                        <Settings className={styles.iconsDrawer} style={{ color: isDrawerOpen ? "#FF6D00" : "#FFFFFF" }} />
                    </ListItemIcon>
                    <ListItemText className={isDrawerOpen ? styles.textColorSelect : styles.textColor}>
                        {menu.wordKey}
                    </ListItemText>
                    {isDrawerOpen
                        ? <ExpandLess style={{ color: "#FF6D00" }} />
                        : <ExpandMore style={{ color: "#FFFFFF" }} />
                    }
                </ListItemButton>
                <Collapse in={isDrawerOpen} >
                    <List>
                        {menu.routes.map((item, index) => (
                            <InsideDrawerNav
                                key={index}
                                menu={item}
                                route={`${route}${menu.route}`}
                                className={styles.link}
                                handleClick={handleClick}
                                level={level + 1}
                            />
                        ))}
                    </List>
                </Collapse>
            </>
        )
    }

    return (
        <Link
            className={styles.link}
            to={menu?.route?.startsWith('/') ? `${route}${menu.route}` : `/${menu.route}`}
        >
            <ListItemButton
                onClick={handleClick}
                sx={{ pl: 3 + level }}
            >
                <ListItemIcon>
                    <img
                        alt="Icono"
                        className={styles.iconsDrawer}
                        src={iconTables}
                        style={{ color: isSelected ? "#FF6D00" : "#FFFFFF" }}
                    />
                </ListItemIcon>
                <ListItemText className={isSelected ? styles.textColorSelect : styles.textColor}>
                    {menu.wordKey}
                </ListItemText>
            </ListItemButton>
        </Link>
    );
};
