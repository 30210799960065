import axiosPost from "./axiosPost";

export const merchantGet = async (id) => {
    const body = {
        service: "MIADemoMerchantGet",
        params: {
            merchantId: id,
        },
    };

    const result = await axiosPost(body);
    return result.result;
};
