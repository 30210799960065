import { ACTIONS } from "consts/Actions";
import { REQUEST_MESSAGES } from "consts/errorMessages";
import axiosPost from "./axiosPost";

export const RequestServiceActions = async (
    body,
    credentials,
    action,
    setSnackbarInfo = () => { }
) => {
    const data = await axiosPost(body, credentials);

    let successMessage;

    switch (action) {
        case ACTIONS.UPDATE:
            successMessage = REQUEST_MESSAGES.UPDATE_SUCCESS;
            break;

        case ACTIONS.INSERT:
            successMessage = REQUEST_MESSAGES.INSERT_SUCCESS;
            break;

        case ACTIONS.DELETE:
            successMessage = REQUEST_MESSAGES.DELETE_SUCCESS;
            break;

        default:
            successMessage = REQUEST_MESSAGES.DEFAULT;
            break;
    }

    if (data.status?.errcode && data.status?.errmsg) {
        setSnackbarInfo({
            message: data.status.errmsg,
            severity: "error",
            open: true,
        });
        return data;
    } else {
        setSnackbarInfo({
            message: successMessage,
            severity: "success",
            open: true,
        });
        return data;
    }
};
