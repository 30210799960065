import axiosPost from "../axiosPost";

export const localidadGet = async ({
  provinciaId = null
}, credentials) => {
  const body = {
    service: "LocalidadGet",
    params: {
      provinciaId
    },
  };

  return await axiosPost(body, credentials);
};