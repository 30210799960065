import React, { useState, useEffect, useCallback } from "react";

// recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userCredentials, routeInfo } from "recoilState/GlobalState";

//Services
import { horarioRangoGet } from "services/services-mia/miakey";

// utils
import XGrid from "components/UI/Grid/XGrid";
import { HeaderButton, Buttons } from "components";
import { Add, Cached, DeleteOutline, EditOutlined } from "@mui/icons-material";

import Modal from "components/UI/Modal/Modal";
import { DeleteModal, InsertModal, UpdateModal } from "./Actions";

// consts
import { rangos as titles } from "consts/titulos-de-tablas";
import { ACTIONS } from "consts/Actions";
import { Routes } from "consts/Routes-App";

// styles
import s from "styles/pages/Pages.module.scss"

const getDia = (dia) => {
  switch (dia) {
    case "LU":
      return "Lunes";
    case "MA":
      return "Martes";
    case "MI":
      return "Miercoles";
    case "JU":
      return "Jueves";
    case "VI":
      return "Viernes";
    case "SA":
      return "Sábado";
    case "DO":
      return "Domingo";
    default:
      return "";
  }
};

export default function HorarioRangos({
  horarioId,
  service
}) {
  const [modalDetails, setModalDetails] = useState({ isOpen: false });
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [rowSelected, setRowSelected] = useState({ status: false, row: {} })

  const credentials = useRecoilValue(userCredentials);
  const setRoute = useSetRecoilState(routeInfo);

  const updateList = useCallback(async () => {
    setIsLoading(true);
    setRows([]);
    const { result } = await horarioRangoGet({
      gateHorarioId: horarioId
    }, credentials);
    setRows(result.map((item) => {
      const {
        gateHorarioId,
        gateHorarioRangoId,
        gateHorarioRangoDia,
        gateHorarioRangoEntrada,
        gateHorarioRangoSalida,
        gateHorarioRangoVersion,
      } = item;

      return {
        id: gateHorarioRangoId,
        gateHorarioId,
        gateHorarioRangoId,
        gateHorarioRangoDia,
        gateHorarioRangoEntrada,
        gateHorarioRangoSalida,
        gateHorarioRangoVersion,
        [titles[0]]: getDia(gateHorarioRangoDia),
        [titles[1]]: gateHorarioRangoEntrada.substring(0, 5),
        [titles[2]]: gateHorarioRangoSalida.substring(0, 5),
      }
    }));
    setIsLoading(false);
  }, [credentials, horarioId]);

  useEffect(() => {
    setRoute(Routes.horarios)
  }, [setRoute])

  useEffect(() => {
    updateList()
  }, [service, updateList]);

  const handleClick = ({ procede, title }) => {
    setModalDetails({ title, procede, isOpen: true });
  };

  const closeModal = () => {
    setModalDetails({ isOpen: false });
  };

  return (
    <main className={s.main}>
      <Modal onClose={closeModal} open={modalDetails.isOpen} title={modalDetails.title}>
        {modalDetails.procede === ACTIONS.UPDATE && (
          <UpdateModal
            horarioRango={rowSelected.row}
            updateList={updateList}
            closeModal={closeModal}
          />
        )}
        {modalDetails.procede === ACTIONS.DELETE && (
          <DeleteModal
            horarioRango={rowSelected.row}
            updateList={updateList}
            closeModal={closeModal}
          />
        )}
        {modalDetails.procede === ACTIONS.INSERT && (
          <InsertModal
            horarioId={horarioId}
            updateList={updateList}
            closeModal={closeModal}
          />
        )}
      </Modal>
      <div className={s.actions}>
        <Buttons>
          <HeaderButton
            text={"Actualizar"}
            onClick={updateList}
            disabled={rowSelected.row.id}
          >
            <Cached />
          </HeaderButton>
          <HeaderButton
            text={"Agregar Rango"}
            handleClick={handleClick}
            data={{
              title: "Agregar Rango Horario",
              procede: ACTIONS.INSERT
            }}
            disabled={rowSelected.row.id || !service.servicioPermiso === 'E'}
          >
            <Add />
          </HeaderButton>
          <HeaderButton
            text={"Editar Rango"}
            handleClick={handleClick}
            data={{
              title: "Editar Rango Horario",
              procede: ACTIONS.UPDATE
            }}
            disabled={!rowSelected.row.id || !service.servicioPermiso === 'E'}
          >
            <EditOutlined />
          </HeaderButton>

          <HeaderButton
            text={"Eliminar Rango"}
            handleClick={handleClick}
            data={{
              title: "Eliminar Rango Horario",
              procede: ACTIONS.DELETE,
            }}
            disabled={!rowSelected.row.id || !service.servicioPermiso === 'E'}
          >
            <DeleteOutline />
          </HeaderButton>
        </Buttons>
      </div>
      <div className={s.container}>
        <XGrid
          rows={rows}
          titles={titles}
          loading={isLoading}
          secondarySelected={rowSelected}
          setSecondarySelected={setRowSelected}
        />
      </div>
    </main>
  );
}
