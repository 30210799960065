import axiosPost from "../axiosPost";

export const horarioRangoDel = async ({
    gateHorarioRangoId,
}, credentials) => {
    const body = {
        service: "GateHorarioRangoDel",
        params: {
            gateHorarioRangoId
        },
    };

    return await axiosPost(body, credentials);
};
