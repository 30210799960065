import React, { useEffect, useState } from "react";

import { Fade, Modal as ModalMUI } from "@mui/material";
import { Typography } from "@mui/material";

import { Close } from "@mui/icons-material"

import styles from "./Modal.module.scss";
import Button from "components/Button/Button";

export default function Modal({
    children,
    title,
    confirmationModal = false,
    onClose = () => { },
    open
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    useEffect(() => {
        const handleKeyUp = ({ key }) => {
            if (key === "Escape") {
                setConfirmationModalOpen(true);
            }
        };

        isOpen && window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [isOpen]);

    const handleCloseModal = () => {
        if (confirmationModal) {
            setConfirmationModalOpen(true)
            return;
        }
        setIsOpen(false)
        onClose();
    };

    return (
        <>
            <ModalMUI
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={styles.modal}
                open={isOpen}
            >
                <Fade in={isOpen} className={styles.modal}>
                    <div className={styles.modal}>
                        <div className={styles.paper}>
                            <Typography
                                variant="h6"
                                fontWeight={100}
                                className={styles.title}
                                style={{
                                    color: title?.toLowerCase().includes("eliminar")
                                        ? "#f00"
                                        : "",
                                }}
                            >
                                {title}
                            </Typography>
                            <hr className={styles.hr} />
                            <Close
                                className={styles.closeButton}
                                onClick={handleCloseModal}
                                role="button"
                            />
                            {children}
                        </div>
                    </div>
                </Fade>
            </ModalMUI>

            {confirmationModal && confirmationModalOpen && (
                <ModalMUI
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={styles.confirmationModal}
                    open={isOpen}
                >
                    <Fade in={isOpen} className={styles.modal}>
                        <div className={styles.confirmationModal}>
                            <div className={styles.paper}>
                                ¿Estás seguro que deseás cancelar?
                                <br />
                                Perderás todos los cambios establecidos
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setConfirmationModalOpen(false);
                                        setIsOpen(false);
                                        onClose();
                                    }}
                                >
                                    <span>Sí</span>
                                </Button>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setConfirmationModalOpen(false);
                                    }}
                                >
                                    <span>No</span>
                                </Button>
                            </div>
                        </div>
                    </Fade>
                </ModalMUI>
            )}
        </>
    );
}
