import React, { useState } from "react";

import { FormButton } from "components";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { horarioDel } from "services/services-mia/miakey";

export default function DeleteModal({ updateList, horario, closeModal }) {
    const setSelected = useSetRecoilState(rowSelected);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);

    const handleSubmit = async () => {
        setInRequest(true);

        const response = await horarioDel({
            gateHorarioId: horario.gateHorarioId,
        }, credentials);

        const functions = {
            setSnackbarInfo,
            setIsOpen: closeModal,
            setSelected,
            updateList,
        };

        handleResponse(
            response,
            "Horario eliminado exitosamente",
            functions
        );

        setInRequest(false);
    };

    return (
        <Formik
            initialValues={{
                nombre: horario.gateHorarioNombre,
            }}
            validationSchema={
                yup.object({
                    nombre: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required)
                })
            }
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <FormikTextInput
                        fullWidth={true}
                        name="nombre"
                        labelText={"Horario"}
                        disabled
                    />
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Eliminar" }}
                        onCloseModal={closeModal}
                    />
                </div>
            </Form>
        </Formik>
    );
}