import React from "react";

import { useRecoilState } from "recoil";
import { rowSelected } from "recoilState/GlobalState";

import { generateColumns } from "services/Local-services";

//import { DataGridPro as DataGrid, LicenseInfo } from "@mui/x-data-grid-pro";
import { DataGrid } from "@mui/x-data-grid";

//import { LICENSE_KEY } from "consts/env";

import classes from "./XGrid.module.scss";

//LicenseInfo.setLicenseKey(LICENSE_KEY);

export default function XGrid({
    rows,
    titles,
    secondarySelected,
    setSecondarySelected,
    loading,
    checkboxSelection = false
}) {
    const [selected, setSelected] = useRecoilState(rowSelected);

    const { row } = secondarySelected ? secondarySelected : selected;
    const setter = setSecondarySelected ? setSecondarySelected : setSelected;

    const columns = generateColumns(titles);

    return (
        <DataGrid
            columns={columns}
            rows={rows || []}
            loading={loading}
            checkboxSelection={checkboxSelection}
            onRowClick={(data) => {
                if (data.row.id === row?.id) {
                    setter({ status: false, row: {} })
                    return;
                }
                setter({ status: true, row: data.row })
            }}
            selectionModel={row?.id || []}
            className={classes.root}
            rowHeight={36}
            autoPageSize
            pagination
            hideFooterSelectedRowCount
            disableMultipleSelection
            disableColumnMenu
        />
    );
}
