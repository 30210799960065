import React, { useState, useEffect, useCallback } from "react";

// recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userCredentials, routeInfo } from "recoilState/GlobalState";

//Services
import { usuarioLugarHorarioDetalleGet } from "services/services-mia/miakey";

// utils
import XGrid from "components/UI/Grid/XGrid";
import { HeaderButton, Buttons } from "components";
import { Cached } from "@mui/icons-material";

// consts
import { dashboardAccesosEstados as titles } from "consts/titulos-de-tablas";
import { Routes } from "consts/Routes-App";

// styles
import s from "styles/pages/Pages.module.scss"

export default function Estados({
    accesoDetailId,
    service
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })
    // const [accesoDetail, setAccesoDetail] = useState({})

    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);

    const updateList = useCallback(async () => {
        setIsLoading(true);
        setRows([]);
        const { result } = await usuarioLugarHorarioDetalleGet({
            gateUsuarioLugarHorarioId: accesoDetailId
        }, credentials);
        setRows(result.map((item) => {
            const {
                gateUsuarioLugarHorarioId,
                gateUsuarioLugarHorarioStatus,
                gateUsuarioLugarHorarioFecha,
            } = item;

            return {
                id: gateUsuarioLugarHorarioId,
                gateUsuarioLugarHorarioId,
                gateUsuarioLugarHorarioStatus,
                gateUsuarioLugarHorarioFecha,
                [titles[0]]: gateUsuarioLugarHorarioStatus === "A" ? "Alta" : "Baja",
                [titles[1]]: gateUsuarioLugarHorarioFecha,
            }
        }));
        setIsLoading(false);
    }, [credentials, accesoDetailId]);

    useEffect(() => {
        setRoute(Routes.accesos)
    }, [setRoute])

    useEffect(() => {
        updateList()
    }, [service, updateList]);

    /*useEffect(() => {
        (async () => {
            setIsLoading(true)
            const { result } = await usuarioLugarHorarioGet({
                gateUsuarioLugarHorarioId: accesoDetailId
            }, credentials);
            setAccesoDetail(result[0]);
            setIsLoading(false)
        })();
    }, [accesoDetailId, credentials])*/

    return (
        <main className={s.main}>
            <div className={s.actions}>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={updateList}
                        disabled={rowSelected.row.id}
                    >
                        <Cached />
                    </HeaderButton>
                </Buttons>
            </div>
            <div className={s.container}>
                <XGrid
                    rows={rows}
                    titles={titles}
                    loading={isLoading}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    );
}
