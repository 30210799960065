import React, { useEffect, useState } from "react";
import XGridDemo from "components/UI/Grid/XGrid";
import { participants as titles } from "consts/titulos-de-tablas";
import classes from "./index.module.scss";
import { Buttons, TransitionModal2, HeaderButton } from "components";
import { RequestServiceGet } from "services/services-mia/RequestServiceGet";
import { useRecoilValue } from "recoil";
import { rowSelected, secondaryModalData, userCredentials } from "recoilState/GlobalState";
import Add from "@mui/icons-material/Add";
import Cached from "@mui/icons-material/Cached";
import Delete from "@mui/icons-material/Delete";
import { Box, Typography } from "@mui/material";
import { ACTIONS } from "consts/Actions";
import { InsertModalParticipants } from "./Actions";
import { DeleteModalParticipants } from "./Actions"
export default function Participants() {
    //Local states 
    const [participants, setParticipants] = useState([]);
    const [secondarySelected, setSecondarySelected] = useState({});

    //Global States
    const xgridRowSelected = useRecoilValue(rowSelected);
    const { action } = useRecoilValue(secondaryModalData);
    const credentials = useRecoilValue(userCredentials);

    useEffect(() => {
        getParticipants();
    }, []);

    const getParticipants = async () => {
        try {
            const body = {
                service: "SBMDocumentoUsuarioGet",
                params: {
                    SBMDocumentoId: xgridRowSelected.row.id
                }
            }
            const states = {
                P: "Pendiente",
                A: "Aceptado",
                R: "Rechazado"
            }
            const response = await RequestServiceGet(body, credentials);
            const responseToParticipants = response.map((participant) => {
                return {
                    id: participant.SBMDocumentoUsuarioId,
                    rol: participant.SBMRolNombre,
                    version: participant.SBMDocumentoUsuarioVersion,
                    [titles[0]]: `${participant.usuarioNombres} ${participant.usuarioApellidos}`,
                    [titles[1]]: participant.tipoDeDocumentoNombre,
                    [titles[2]]: participant.usuarioNroDeDocumentoX,
                    [titles[3]]: participant.SBMRolNombre,
                    [titles[4]]: participant.usuarioMail,
                    [titles[5]]: participant.SBMDocumentoUsuarioFechaEstado,
                    [titles[6]]: states[participant.SBMDocumentoUsuarioEstado]
                }
            });
            setParticipants(responseToParticipants);
        } catch (e) {
            console.log("Hola");
        }
    }

    console.log("Segunda seleccion", secondarySelected);
    return (
        <section className={classes.container}>
            <Box display={"flex"}>
                <Typography variant="h1" style={{ fontSize: "20px" }}>
                    {`Documento: ${xgridRowSelected.row.nombre}`}
                </Typography>
            </Box>
            {/**
                * Actions buttons
            */}
            <Box display={"flex"} justifyContent={"flex-end"}>


                <Buttons filtersApplied={true}>
                    <HeaderButton
                        disabled={secondarySelected.status === true}
                        text={"Crear"}
                        data={{
                            action: ACTIONS.INSERT,
                            title: "Invitar Participante",
                            button: "Continuar",
                            secondaryModal: true
                        }}
                    >
                        <Add />
                    </HeaderButton>

                    <HeaderButton
                        text={"Actualizar"}
                        updateList={() => getParticipants()}
                    >
                        <Cached />
                    </HeaderButton>

                    <HeaderButton
                        disabled={secondarySelected.status === false}
                        text={"Eliminar"}
                        data={{
                            action: ACTIONS.DELETE,
                            title: "Borrar Participante",
                            button: "Enviar",
                            secondaryModal: true
                        }}>
                        <Delete />
                    </HeaderButton>
                </Buttons>
            </Box>
            {

                <TransitionModal2>
                    {
                        action === ACTIONS.INSERT && (
                            <InsertModalParticipants updateList={getParticipants} />
                        )
                    }
                    {
                        action === ACTIONS.DELETE && (
                            <DeleteModalParticipants secondaryRowSelected={secondarySelected} updateList={getParticipants} />
                        )
                    }
                </TransitionModal2>

            }
            <XGridDemo
                titles={titles}
                rows={participants}
                secondarySelected={secondarySelected}
                setSecondarySelected={setSecondarySelected}
            />
        </section>


    );
}