
import axiosPost from "../axiosPost";

export const usuarioLugarHorarioIns = async ({
  usuarioId,
  gateZonaId,
  gateLugarId,
  gateHorarioId,
  gateUsuarioLugarHorarioStatus,
  gateUsuarioLugarHorarioFH,
}, credentials) => {
  const body = {
    service: "GateUsuarioLugarHorarioIns",
    params: {
      usuarioId,
      gateZonaId,
      gateLugarId,
      gateHorarioId,
      gateUsuarioLugarHorarioStatus,
      gateUsuarioLugarHorarioFH,
    },
  };

  return await axiosPost(body, credentials);
};