import axiosPost from "../axiosPost";

export const zonaUpd = async ({
    gateZonaId,
    gateZonaNombre,
    gateZonaVersion
}, credentials) => {
    const body = {
        service: "GateZonaUpd",
        params: {
            gateZonaId,
            gateZonaNombre,
            gateZonaVersion
        },
    };

    return await axiosPost(body, credentials);
};
