import axiosPost from "../axiosPost";

export const usuarioLugarHorarioDetalleGet = async ({
  gateUsuarioLugarHorarioId
}, credentials) => {
  const body = {
    service: "GateUsuarioLugarHorarioDetalleGet",
    params: {
      gateUsuarioLugarHorarioId
    },
  };

  return await axiosPost(body, credentials);
};