import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import moment from "moment";
// styles
import s from "styles/pages/Pages.module.scss"
// utils
import { DetailModal } from "./Actions";
import XGridServer from "components/UI/Grid/XGridServer";

// consts
import { usuariosXServicio as titles } from "consts/titulos-de-tablas";
import { ACTIONS } from "consts/Actions";
import { Routes } from "consts/Routes-App";
//Assets
import { Cached, SearchOutlined } from '@mui/icons-material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { routeInfo, userCredentials } from "recoilState/GlobalState";
import { clienteUsuarioGet } from "services/services-mia";

import Filters from "components/Filters";

import { useService } from "components/route/ServiceRoute";
import Modal from "components/UI/Modal/Modal";
import { Buttons, HeaderButton } from "components";

export default function UsuariosMIAKey() {
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })
    const [forceUpload, setForceUpload] = useState(false);

    const { service } = useService()

    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);

    const updateList = useCallback(() => { setForceUpload((value) => !value); }, [])

    const formik = useFormik({
        initialValues: {
            apellido: "",
            dni: "",
            rol: "Todos",
        },
        onSubmit: () => {
            updateList()
        },
    });

    const getUsers = async (page, pageSize) => {
        const { result, total } = await clienteUsuarioGet({
            merchantId: credentials?.merchantId,
            tipoRelacion: "S",
            servicioCod: service.servicioCod,
            page: page + 1,
            pageSize,
            usuarioNroDeDocumentoX: formik.values.dni === "" ? null : formik.values.dni,
            usuarioApellidos: formik.values.apellido === "" ? null : formik.values.apellido,
            rolCod: formik.values.rol === "Todos" ? null : formik.values.rol
        }, credentials);

        const newRows = await result.map((usuario) => {
            return {
                id: usuario.merchantServicioUsuarioId,
                usuarioId: usuario.usuarioId,
                nombreServicio: usuario.servicioNombre,
                servicioId: usuario.servicioId,
                estadoCod: usuario.relacionStatusCod,
                email: usuario.usuarioMail,
                rolId: usuario.rolId,
                rolCod: usuario.rolCod,
                rolNombre: usuario.rolNombre,
                [titles[0].field]: usuario.usuarioNombreCompleto,
                [titles[1].field]: usuario.usuarioNroDeDocumentoX,
                [titles[2].field]: usuario.tipoDeDocumentoCod,
                [titles[3].field]: usuario.usuarioSexo,
                [titles[4].field]: usuario.usuarioFechaDeNacimiento,
                [titles[5].field]: usuario.usuarioMail,
                [titles[6].field]: usuario.rolNombre,
                [titles[7].field]: usuario.usuarioId === null ? 'NO' : 'SI',
                [titles[8].field]: usuario.usuarioFechaDeAlta ? moment(usuario.usuarioFechaDeAlta).format("YYYY-MM-DD").toString() : null,
                [titles[9].field]: usuario.relacionStatus,
                [titles[10].field]: usuario.usuarioFecha,
            };
        });
        return {
            rows: newRows,
            rowCount: total,
        };
    };

    useEffect(() => {
        setRoute(Routes.usuariosXServicio)
    }, [setRoute])

    useEffect(() => {
        updateList()
    }, [service, updateList])


    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };

    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };

    return (
        <main className={s.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.DETAIL && (
                    <DetailModal
                        usuarioId={rowSelected?.row?.usuarioId}
                        service={service}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s["filters-actions"]}>
                <Filters
                    formik={formik}
                    inputsRender={
                        [
                            { type: "text", name: "apellido", label: "Apellido", disabled: false },
                            { type: "text", name: "dni", label: "Documento", disabled: false },
                        ]
                    }
                />
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={updateList}
                        disabled={rowSelected.row.id}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Ver detalle"}
                        handleClick={handleClick}
                        data={{
                            title: "Detalle de Accesos",
                            procede: ACTIONS.DETAIL
                        }}
                        disabled={!rowSelected.row.id || !service.servicioPermiso === 'E'}
                    >
                        <SearchOutlined />
                    </HeaderButton>
                </Buttons>
            </div>

            <div className={s.container}>
                <XGridServer
                    titles={titles}
                    updateList={getUsers}
                    forceUpload={forceUpload}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    );
}
