import axiosPost from "../axiosPost";

export const eventoGet = async ({
    fechaDesde,
    fechaHasta,
    gateZonaId,
    gateLugarId,
    usuarioNombres
}, credentials) => {
    const body = {
        service: "GateEventoGet",
        params: {
            merchantId: credentials.merchantId,
            fechaDesde,
            fechaHasta,
            gateZonaId,
            gateLugarId,
            usuarioNombres
        },
    };

    return await axiosPost(body, credentials);
};
