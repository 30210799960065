import axios from "axios";
import { serviceURL } from "consts/ServiceURL";
import { APLICACION_COD } from "consts/env";

export const getUserCredentials = async () => {
    if (
        localStorage.getItem("hooliToken") &&
        localStorage.getItem("sessionId")
    ) {
        return {
            hooliToken: localStorage.getItem("hooliToken"),
            sessionId: localStorage.getItem("sessionId"),
            merchantId: localStorage.getItem("merchantId"),
            merchantRZ: localStorage.getItem("merchantRZ"),
            merchantNombre: localStorage.getItem("merchantNombre"),
            merchantCUIT: localStorage.getItem("merchantCUIT")
        };
    }

    return null;
    /*
    let userCredentials
    try {
        userCredentials = await axios.post(
            serviceURL,
            {
                service: "TemporalSession",
                params: {},
            }, {
            headers: {
                "Content-Type": "application/json",
                "x-origin-id": APLICACION_COD
            }
        }
        );
    } catch (err) {
        console.log(`Error: ${err}`);
    }

    if (!userCredentials.data || userCredentials.data === null) {
        console.log(`Fetch failed`);
    }

    localStorage.setItem(
        "hooliToken",
        userCredentials.data.result[0].hooliToken
    );
    localStorage.setItem("sessionId", userCredentials.data.result[0].sessionId);
    localStorage.setItem("merchantId", 1);
    localStorage.setItem("merchantRZ", "Moov");
    localStorage.setItem("merchantNombre", "Moov");
    localStorage.setItem("merchantCUIT", "30-71512361-2");

    const newCredentials = {
        ...userCredentials.data.result[0],
        merchantId: 1,
        merchantRZ: "Moov",
        merchantNombre: "Moov",
        merchantCUIT: "30-71512361-2",
    };

    return newCredentials;*/
};
