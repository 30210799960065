import { Formik, Form } from "formik";
import React from "react";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";
import { FormButton } from "components";
import { RequestServiceActions } from "services/services-mia/RequestServiceActions";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { secondaryModalData, snackbarData, userCredentials } from "recoilState/GlobalState";
export default function Delete({ secondaryRowSelected, updateList }) {
    console.log("Esto es en delete", secondaryRowSelected)
    const credentials = useRecoilValue(userCredentials);
    const setSnackbarInfo = useSetRecoilState(snackbarData)
    const setSecondaryModal = useSetRecoilState(secondaryModalData);
    const hanldeSubmit = async () => {
        const body = {
            service: "SBMDocumentoUsuarioDel",
            params: {
                SBMDocumentoUsuarioId: secondaryRowSelected.row.id
            }
        }
        const response = await RequestServiceActions(body, credentials, null, setSnackbarInfo);
        console.log("Esto es response", response);
        setSecondaryModal({ isOpen: false })
        await updateList()
    }
    return (
        <Formik
            initialValues={{
                name: secondaryRowSelected.row["Nombre y apellido"],
                rol: secondaryRowSelected.row["rol"]
            }}
            onSubmit={hanldeSubmit}
        >
            <Form>
                <FormikTextInput name={"name"} labelText={"Nombre"} disabled={true} />
                <FormikTextInput name={"rol"} labelText={"Rol"} disabled={true} />
                <FormButton
                    newFormData={{ button: "Borrar" }}
                />
            </Form>
        </Formik>
    );
}