import axiosPost from "../axiosPost";

export const kioscoUpd = async ({
    gateDeviceId,
    gateDeviceIdentificacion,
    gateDeviceVersion
}, credentials) => {
    const body = {
        service: "GateDeviceUpd",
        params: {
            gateDeviceId,
            gateDeviceIdentificacion,
            gateDeviceVersion
        },
    };

    return await axiosPost(body, credentials);
};
