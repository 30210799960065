import React, { useEffect, useState } from "react";
import XGridDemo from "components/UI/Grid/XGrid";
import { document as titles } from "consts/titulos-de-tablas";
import tableStyles from "styles/pages/Tables.module.scss";
import { Buttons, TransitionModal } from "components";
import { ACTIONS } from "consts/Actions";
import { HeaderButton } from "components";
import Add from "@mui/icons-material/Add";
import Cached from "@mui/icons-material/Cached";
import EditOutlined from "@mui/icons-material/EditOutlined";
import Delete from "@mui/icons-material/Delete";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Filters from "components/Filters";
import { useFormik } from "formik";
import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { modalData, rowSelected, userCredentials, userInfo } from "recoilState/GlobalState";
import { DeleteModal, InsertModal, UpdateModal, UploadModal } from "./Actions";
import Participants from "./Actions/Participants";
import { RequestServiceGet } from "services/services-mia/RequestServiceGet";
import { merchantServicioUsuarioGet } from "services/services-mia";


export default function Documents() {
    //Local States 
    const [rows, setRows] = useState([]);
    const [documentType, setDocuemntType] = useState([]);
    const [documentStatus, setDocumentStatus] = useState([]);
    const userData = useRecoilValue(userInfo);
    //Global States
    const { action } = useRecoilValue(modalData);
    const credentials = useRecoilValue(userCredentials)
    const xgridRowSelected = useRecoilValue(rowSelected);

    // Hooks 
    const formik = useFormik({
        initialValues: {
            tipo: { tipoCod: "todos", tipoNombre: "Todos" },
            estado: { estadoCod: "todos", estadoNombre: "Todos" },
        },
        onSubmit: (values) => {
            const tipo = values.tipo === "todos" ? null : Number(values.tipo);
            const estado = values.estado === "todos" ? null : Number(values.estado);

            return getDocuments(tipo, estado);
        },
    });

    useEffect(() => {
        Promise.all([getDocuments(), getDocumentTypes(), getDocumentStatus(), getUserRol()])
    }, []);

    const getUserRol = async () => {
        const response = await merchantServicioUsuarioGet({ servicioHooliCod: "SIGN", usuarioId: userData.usuarioId }, credentials);
        console.log("Esto es response", response);
    }

    const getDocuments = async (documentId, estadoId) => {
        try {
            const body = {
                service: "SBMDocumentoGet",
                params: {
                    SBMTipoDocumentoId: documentId,
                    SBMEstadoId: estadoId
                },
            }
            const response = await RequestServiceGet(body, credentials);
            const responseToRows = response.map((document) => {
                return {
                    id: document.SBMDocumentoId,
                    nombre: document.SBMDocumentoNombre,
                    tipoDocumentoId: document.SBMTipoDeDocumentoId,
                    version: document.SBMDocumentoVersion,
                    requiereFirma: document.SBMDocumentoRequiereFirma,
                    descripcion: document.SBMDocumentoDescripcion,
                    firmantesInternos: document.SBMDocumentoFirmantesInternos,
                    firmantesExternos: document.SBMDocumentoFirmantesExternos,
                    fechaDocumento: document.SBMDocumentoFecha,
                    [titles[0]]: document.SBMDocumentoNombre,
                    [titles[1]]: document.SBMTipoDeDocumentoNombre,
                    [titles[2]]: document.SBMDocumentoFecha,
                    [titles[3]]: document.SBMDocumentoDescripcion,
                    [titles[4]]: document.SBMDocumentoRequiereFirma,
                    [titles[5]]: document.SBMDocumentoFirmantesInternos,
                    [titles[6]]: document.SBMDocumentoFirmantesExternos,
                    [titles[7]]: document.SBMDocumentoFechaEstado,
                    [titles[8]]: document.SBMEstadoNombre,
                }
            })
            setRows(responseToRows);
        } catch (e) {

        }

    }
    const getDocumentTypes = async () => {
        try {
            const body = {
                service: "SBMTipoDeDocumentoGet",
                params: {}
            }
            const response = await RequestServiceGet(body, credentials);
            const responseToDocumentType = response.map((document) => {
                const { tipoDeDocumentoId, tipoDeDocumentoNombre } = document;
                return {
                    tipoCod: tipoDeDocumentoId,
                    tipoNombre: tipoDeDocumentoNombre,

                }
            })
            setDocuemntType(responseToDocumentType);
        } catch (e) {
            //Manejar error; 
        }
    }
    const getDocumentStatus = async () => {
        try {
            const body = {
                service: "SBMEstadoGet",
                params: {}
            }
            const response = await RequestServiceGet(body, credentials);
            const responseToDocumentStatus = response.map((document) => {
                const { SBMEstadoId, SBMEstadoNombre } = document;
                return {
                    estadoCod: SBMEstadoId,
                    estadoNombre: SBMEstadoNombre,

                }
            })
            setDocumentStatus(responseToDocumentStatus);
        } catch (e) {
            //Manejar error; 
        }
    }
    return (
        <section className={tableStyles.section}>
            <header>
                <Box display={"flex"} >

                    <Filters
                        formik={formik}
                        inputsRender={[
                            { type: "select", name: "tipo", label: "Tipo documento", disabled: false, options: [{ tipoCod: "todos", tipoNombre: "Todos" }, ...documentType], firstOptionKey: "tipoCod", firstOptionName: "tipoNombre", firstOptionValue: "tipoCod" },
                            { type: "select", name: "estado", label: "Estado", disabled: false, options: [{ estadoCod: "todos", estadoNombre: "Todos" }, ...documentStatus], firstOptionKey: "estadoCod", firstOptionName: "estadoNombre", firstOptionValue: "estadoCod" }
                        ]}
                    />

                    {/**
                     * Actions buttons
                     */}
                    <Buttons filtersApplied={true}>
                        <HeaderButton
                            disabled={xgridRowSelected.status === true}
                            text={"Crear"}
                            data={{
                                action: ACTIONS.INSERT,
                                title: "Nuevo Documento",
                                button: "Registrar",
                            }}
                        >
                            <Add />
                        </HeaderButton>
                        <HeaderButton
                            disabled={xgridRowSelected.status === false}
                            text={"Participantes"}
                            data={{
                                action: "participants",
                                title: "Participantes",
                                button: "Enviar",
                            }}
                        >
                            <AccountCircleIcon />
                        </HeaderButton>
                        <HeaderButton
                            disabled={xgridRowSelected.status === false}
                            text={"Subir documento"}
                            data={{
                                action: "uploadfile",
                                title: "Subir Documento",
                                button: "Subir documento",
                            }}
                        >
                            <CloudUploadIcon />
                        </HeaderButton>
                        <HeaderButton
                            text={"Actualizar"}
                            updateList={() => getDocuments()}
                        >
                            <Cached />
                        </HeaderButton>
                        <HeaderButton
                            disabled={xgridRowSelected.status === false}
                            text={"Editar"}
                            data={{
                                action: ACTIONS.UPDATE,
                                title: "Editar",
                                button: "Enviar",
                            }}>
                            <EditOutlined />
                        </HeaderButton>
                        <HeaderButton
                            disabled={xgridRowSelected.status === false}
                            text={"Eliminar"}
                            data={{
                                action: ACTIONS.DELETE,
                                title: "Eliminar",
                                button: "Enviar",
                            }}>
                            <Delete />
                        </HeaderButton>
                    </Buttons>
                </Box>
            </header>


            <TransitionModal confirmationModal={
                (action === ACTIONS.INSERT || action === ACTIONS.UPDATE) ??
                true}>
                {
                    action === ACTIONS.INSERT && (
                        <InsertModal updateList={getDocuments} />
                    )
                }
                {
                    action === ACTIONS.UPDATE && (
                        <UpdateModal updateList={getDocuments} />
                    )
                }
                {
                    action === ACTIONS.DELETE && (
                        <DeleteModal updateList={getDocuments} />
                    )
                }
                {
                    action === "participants" && (
                        <Participants />
                    )
                }
                {
                    action === "uploadfile" && (
                        <UploadModal />
                    )
                }


            </TransitionModal>
            <XGridDemo
                titles={titles}
                rows={rows}
            />


        </section>
    );
}