import { formatIdxId } from "components/utils/formatIdxId";
import axiosPost from "./axiosPost";
import { X_API_KEY } from "consts/env";
export const MIAUsuarioCheck = async (dni, typeDocument) => {

    const body = {
        service: "MIAUsuarioCheck",
        params: {
            tipoDeDocumentoId: typeDocument,
            usuarioNroDeDocumentoX: dni,
        },
    };

    const result = await axiosPost(
        body,
        null,
        X_API_KEY
    );
    return result.status;
};
