import React, { useState, useEffect, useCallback, useRef } from "react";

// recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userCredentials, routeInfo, snackbarData } from "recoilState/GlobalState";

//Services
import { accesoGet, zonaGet, eventoGet } from "services/services-mia/miakey";

// utils
import { Form, Formik } from "formik";
import * as yup from "yup";
import XGrid from "components/UI/Grid/XGrid";
import { Button } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

// consts
import { eventos as titles } from "consts/titulos-de-tablas";
import { Routes } from "consts/Routes-App";
import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

// styles
import s from "styles/pages/Pages.module.scss"
import styles from "./Eventos.module.scss";

import { useService } from "components/route/ServiceRoute";
import { transformDate } from "services/Local-services";
import moment from "moment";
// import { merchantServicioUsuarioGet } from "services/services-mia";

export default function Accesos() {
    const [isLoading, setIsLoading] = useState(false);
    const [inRequest, setInRequest] = useState(false);
    const [rows, setRows] = useState([]);

    const [zonas, setZonas] = useState([]);
    const [accesos, setAccesos] = useState([]);
    const [accesosFiltrado, setAccesosFiltrado] = useState([]);
    // const [usuarios, setUsuarios] = useState([]);

    const { service } = useService();

    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const formikRef = useRef();

    const updateList = useCallback(async ({
        zona = null,
        acceso = null,
        usuario = null,
        fechaDesde = null,
        fechaHasta = null,
    }) => {
        setIsLoading(true);
        setRows([]);

        const { result } = await eventoGet({
            fechaDesde: fechaDesde ?? moment().format("YYYY-MM-DD").toString(),
            fechaHasta: fechaHasta ?? moment().format("YYYY-MM-DD").toString(),
            gateZonaId: zona || null,
            gateLugarId: acceso || null,
            usuarioNombres: usuario || null,
        }, credentials);
        setRows(result.map((item) => {
            const {
                gateESHistoFH,
                gateESHistoId,
                gateESHistoStatusCode,
                gateLugarId,
                gateLugarNombre,
                gateZonaId,
                gateZonaNombre,
                usuarioApellidos,
                usuarioId,
                usuarioNombres,
            } = item;

            return {
                id: gateESHistoId,
                gateESHistoFH: String(gateESHistoFH),
                gateESHistoId,
                gateESHistoStatusCode,
                gateLugarId,
                gateLugarNombre,
                gateZonaId,
                gateZonaNombre,
                usuarioApellidos,
                usuarioId,
                usuarioNombres,
                [titles[0]]: `${usuarioNombres} ${usuarioApellidos}`,
                [titles[1]]: gateESHistoStatusCode === "E" ? "Entrada" : "Salida",
                [titles[2]]: `${transformDate(gateESHistoFH.substring(0, 10))} ${gateESHistoFH.substring(11, 16)}`,
                [titles[3]]: gateZonaNombre,
                [titles[4]]: gateLugarNombre,
            };
        }));
        setIsLoading(false);
    }, [credentials]);

    useEffect(() => {
        setRoute(Routes.eventos)
    }, [setRoute])

    useEffect(() => {
        if (Object.keys(credentials).length > 0 && service?.servicioCod) {
            (async () => {
                const { result: resultZonas } = await zonaGet({}, credentials);
                const { result: resultAccesos } = await accesoGet({}, credentials);
                setZonas(resultZonas);
                setAccesos(resultAccesos);
                setAccesosFiltrado([...resultAccesos])
                /*const { result: resultUsuarios } = await merchantServicioUsuarioGet({
                    servicioHooliCod: service.servicioCod
                }, credentials);
                setUsuarios(resultUsuarios);*/
            })();
        }
    }, [credentials, service])

    const handleSubmit = async (values) => {
        setInRequest(true);
        await updateList(values)
        setInRequest(false);
    };

    const handleChangeZona = (event) => {
        const zonaId = Number(event.target.value)
        formikRef.current.setFieldValue("zona", zonaId)
        formikRef.current.setFieldValue("acceso", "")
        if (zonaId) {
            const accesosFiltrado = accesos.filter((acceso) => (acceso.gateZonaId = zonaId))
            setAccesosFiltrado(accesosFiltrado)
            return
        }
        setAccesosFiltrado([...accesos])
    }

    return (
        <main className={s.main}>
            <div className={s.filters}>
                <div className={styles.filterContainer}>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            zona: "",
                            acceso: "",
                            usuario: "",
                            fechaDesde: moment().format("YYYY-MM-DD").toString(),
                            fechaHasta: moment().format("YYYY-MM-DD").toString(),
                        }}
                        validationSchema={yup.object({
                            fechaDesde: yup.date(FORM_ERROR_MESSAGES.date)
                                .nullable(),
                            fechaHasta: yup.date(FORM_ERROR_MESSAGES.date)
                                .nullable()
                                .when("fechaDesde", (fechaDesde, yup) => {
                                    fechaDesde && yup.min(fechaDesde, "Debe ser mayor a Fecha Desde")
                                }),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            try {
                                handleSubmit(values);
                            } catch (e) {
                                setSnackbarInfo({
                                    message: e.errmsg,
                                    severity: "error",
                                    open: true,
                                });
                            } finally {
                                setSubmitting(false);
                            }
                        }}
                    >
                        <Form
                            className={styles.inputs}
                            noValidate
                            style={{ width: "100%" }}
                        >
                            <div className={styles.filters}>
                                <FormikTextInput
                                    fullWidth={false}
                                    id="usuario"
                                    labelText={"Usuario"}
                                    name="usuario"
                                    type="text"
                                    variant="standard"
                                />

                                <FormikTextInput
                                    fullWidth={false}
                                    id="fechaDesde"
                                    labelText={"Fecha Desde"}
                                    name="fechaDesde"
                                    type="date"
                                    variant="standard"
                                />

                                <FormikTextInput
                                    fullWidth={false}
                                    id="fechaHasta"
                                    labelText={"Fecha Hasta"}
                                    name="fechaHasta"
                                    type="date"
                                    variant="standard"
                                />
                                <FormikSelectInput
                                    fullWidth={false}
                                    labelText={"Zonas"}
                                    name="zona"
                                    onChange={handleChangeZona}
                                    id="gateZonaId"
                                    variant="standard"
                                >
                                    <option value="">Todos</option>
                                    {zonas?.map(
                                        ({ gateZonaId, gateZonaNombre }) => (
                                            <option
                                                key={gateZonaId}
                                                value={gateZonaId || ""}
                                            >
                                                {gateZonaNombre}
                                            </option>
                                        )
                                    )}
                                </FormikSelectInput>

                                <FormikSelectInput
                                    disabled={!accesosFiltrado?.length}
                                    fullWidth={false}
                                    labelText={"Accesos"}
                                    name="acceso"
                                    id="gateLugarId"
                                    variant="standard"
                                >
                                    <option value="">Todos</option>
                                    {accesosFiltrado?.map(
                                        ({ gateLugarId, gateLugarNombre }) => (
                                            <option
                                                key={gateLugarId}
                                                value={gateLugarId || ""}
                                            >
                                                {gateLugarNombre}
                                            </option>
                                        )
                                    )}
                                </FormikSelectInput>

                                <Button
                                    style={{ marginLeft: "auto" }}
                                    disabled={inRequest}
                                    type="submit"
                                >
                                    Buscar
                                </Button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
            <div className={s.container}>
                <XGrid
                    rows={rows}
                    titles={titles}
                    loading={isLoading || inRequest}
                />
            </div>
        </main>
    );
}
