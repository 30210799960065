import axiosPost from "./axiosPost";

export const connectionIns = async (
    merchantId,
    servicioCod,
    usuarioId,
    rolCod,
    tipoDeDocumentoCod,
    DNI,
    tipoRelacion,
    email,
    credentials
) => {
    const body = {
        service: "ConnectionIns",
        params: {
            merchantId: merchantId,
            servicioHooliCod: servicioCod,
            rolCod: rolCod,
            usuarioId: usuarioId,
            tipoDeDocumentoCod: tipoDeDocumentoCod,
            usuarioNroDeDocumentoX: DNI,
            merchantServicioUsuarioTiporel: tipoRelacion,
            usuarioMail: email,
        },
    };
    const result = await axiosPost(body, credentials);
    return result;
};
