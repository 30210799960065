import React, { useCallback, useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { accesoGet, horarioGet, usuarioLugarHorarioIns, zonaGet } from "services/services-mia/miakey";
import { Grid } from "@mui/material";

const estados = [
    { estadoCod: "A", estadoNombre: "Alta" },
    { estadoCod: "B", estadoNombre: "Baja" },
];

export default function InsertModal({ updateList, usuarioId, closeModal }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [estado, setEstado] = useState("A");

    const [zonas, setZonas] = useState([]);
    const [accesos, setAccesos] = useState([]);
    const [accesosFiltrado, setAccesosFiltrado] = useState([]);
    const [horarios, setHorarios] = useState([]);

    const setSelected = useSetRecoilState(rowSelected);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const formikRef = useRef();

    const getAccesos = useCallback((zonaId) => {
        if (!zonaId) return;
        setIsLoading(true);
        const result = accesos.filter((acceso) => (acceso.gateZonaId === zonaId))
        setAccesosFiltrado(result)
        selectAcceso(result[0].gateLugarId)
        setIsLoading(false);
    }, [accesos])

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            setEstado("A")
            formikRef.current.setFieldValue("estado", "A")
            const { result: resultZonas } = await zonaGet({}, credentials)
            const { result: resultAccesos } = await accesoGet({}, credentials)
            const { result: resultHorarios } = await horarioGet({}, credentials)
            setAccesos(resultAccesos);
            setHorarios(resultHorarios);
            setZonas(resultZonas);
            setIsLoading(false)
        })();
    }, [credentials])

    useEffect(() => {
        if (zonas.length > 0) {
            selectZona(zonas[0].gateZonaId)
            getAccesos(zonas[0].gateZonaId);
        }
    }, [getAccesos, zonas]);

    useEffect(() => {
        if (horarios.length > 0) {
            selectHorario(horarios[0].gateHorarioId)
        }
    }, [horarios]);

    const selectZona = (zonaId) => {
        formikRef.current.setFieldValue("zona", zonaId)
    }

    const selectAcceso = (accesoId) => {
        formikRef.current.setFieldValue("acceso", accesoId)
    }

    const selectHorario = (horarioId) => {
        formikRef.current.setFieldValue("horario", horarioId)
    }

    const handleChangeZona = (event) => {
        const zonaId = event.target.value;
        selectZona(zonaId)
        getAccesos(zonaId);
    }

    const handleChangeAcceso = (event) => {
        const accesoId = event.target.value;
        selectAcceso(accesoId);
    }

    const handleChangeEstado = (event) => {
        const currentEstado = event.target.value;
        formikRef.current.setFieldValue("estado", currentEstado)
        setEstado(currentEstado)
    }

    const handleSubmit = async ({
        zona,
        acceso,
        horario,
        estado,
        fecha,
    }) => {
        setInRequest(true);

        const response = await usuarioLugarHorarioIns({
            usuarioId,
            gateZonaId: zona,
            gateLugarId: acceso,
            gateHorarioId: horario,
            gateUsuarioLugarHorarioStatus: estado,
            gateUsuarioLugarHorarioFH: fecha,
        }, credentials);

        const functions = {
            setSnackbarInfo,
            setIsOpen: closeModal,
            setSelected,
            updateList,
        };

        handleResponse(
            response,
            "Acceso agregado exitosamente",
            functions
        );

        setInRequest(false);
    };

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                zona: "",
                acceso: "",
                horario: "",
                estado: "A",
                fecha: new Date().toISOString().substring(0, 10),
            }}
            validationSchema={
                yup.object({
                    zona: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required),
                    acceso: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required),
                    horario: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required),
                    estado: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required),
                    fecha: yup.date(FORM_ERROR_MESSAGES.date)
                        .required(FORM_ERROR_MESSAGES.required)
                        .max(new Date(), "Fecha debe ser menor o igual a hoy"),
                })
            }
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item md={6} lg={6}>
                        <FormikTextInput
                            name="fecha"
                            labelText={"Fecha"}
                            type="date"
                            placeholder="Ingrese una fecha"
                        />
                    </Grid>
                    <Grid item md={6} lg={6}>
                        <FormikSelectInput
                            name="estado"
                            labelText="Estado"
                            onChange={handleChangeEstado}
                        >
                            {estados.map(({ estadoCod, estadoNombre }) => (
                                <option
                                    key={estadoCod}
                                    data-id={estadoCod}
                                    value={estadoCod}
                                >
                                    {estadoNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                    </Grid>
                    {estado === "A" &&
                        <>
                            <Grid item md={6} lg={6}>
                                <FormikSelectInput
                                    name="zona"
                                    labelText="Zona"
                                    onChange={handleChangeZona}
                                >
                                    {zonas.map(({ gateZonaId, gateZonaNombre }) => (
                                        <option
                                            key={gateZonaId}
                                            data-id={gateZonaId}
                                            value={gateZonaId}
                                        >
                                            {gateZonaNombre}
                                        </option>
                                    ))}
                                </FormikSelectInput>
                            </Grid>
                            <Grid item md={6} lg={6}>
                                {accesosFiltrado.length > 0
                                    ? <FormikSelectInput
                                        name="acceso"
                                        labelText="Acceso"
                                        onChange={handleChangeAcceso}
                                    >
                                        {accesosFiltrado.map(({ gateLugarId, gateLugarNombre }) => (
                                            <option
                                                key={gateLugarId}
                                                data-id={gateLugarId}
                                                value={gateLugarId}
                                            >
                                                {gateLugarNombre}
                                            </option>
                                        ))}
                                    </FormikSelectInput>
                                    : <FormikTextInput
                                        name="acceso"
                                        labelText={"Acceso"}
                                        value="No hay accesos disponibles"
                                        disable={true}
                                    />

                                }
                            </Grid>
                            <Grid item md={6} lg={6}>
                                <FormikSelectInput
                                    labelText="Horario"
                                    fullWidth={true}
                                    id="horario"
                                    name="horario"
                                >
                                    {horarios?.map((horario) => (
                                        <option
                                            value={horario.gateHorarioId}
                                            key={horario.gateHorarioId}
                                        >
                                            {horario.gateHorarioNombre}
                                        </option>
                                    ))}
                                </FormikSelectInput>
                            </Grid>
                        </>}
                    <FormButton
                        inRequest={inRequest || isLoading}
                        newFormData={{ button: "Agregar" }}
                        onCloseModal={closeModal}
                    />
                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </Grid>
            </Form>
        </Formik>
    );
}
