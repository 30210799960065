import axiosPost from "../axiosPost";

export const lugarDeviceGet = async ({
    gateDeviceId,
}, credentials) => {
    const body = {
        service: "GateLugarDeviceGet",
        params: {
            gateDeviceId,
        },
    };

    return await axiosPost(body, credentials);
};
