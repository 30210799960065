import axiosPost from "../axiosPost";

export const zonaDel = async ({
    gateZonaId,
}, credentials) => {
    const body = {
        service: "GateZonaDel",
        params: {
            gateZonaId,
        },
    };

    return await axiosPost(body, credentials);
};
