import React from "react";

import { Button } from "components";

import AccesoDetails from "../../accesos-detalles/AccesoDetails";

export default function DetailModal({
    usuarioId, closeModal, service
}) {
    return (
        <>
            <AccesoDetails usuarioId={usuarioId} service={service} />
            <div className={"closeButtonContainer"}>
                <Button
                    className={"button"}
                    onClick={closeModal}
                >
                    Cerrar
                </Button>
            </div>

        </>
    );
}