import axiosPost from "./axiosPost";

export const connectionUpd = async (msuId, estado, mail, rol, credentials) => {
    const body = {
        service: "ConnectionUpd",
        params: {
            merchantServicioUsuarioStatus: estado,
            merchantServicioUsuarioId: msuId,
            merchantServicioUsuarioMail: mail,
            rolCod: rol,
        },
    };

    const result = await axiosPost(body, credentials);

    return result;
};
