import axiosPost from "../axiosPost";

export const accesoUpd = async ({
    gateLugarId,
    gateZonaId,
    gateLugarNombre,
    gateLugarCalle,
    localidadId,
    gateLugarCasa,
    gateLugarUnidad,
    gateLugarPiso,
    gateLugarMinimo,
    gateLugarMaximo,
    gateLugarLatitud,
    gateLugarLongitud,
    gateLugarRadio,
    gateLugarVersion
}, credentials) => {
    const body = {
        service: "GateLugarUpd",
        params: {
            gateLugarId,
            gateZonaId,
            gateLugarNombre,
            gateLugarCalle,
            localidadId,
            gateLugarCasa,
            gateLugarUnidad,
            gateLugarPiso,
            gateLugarMinimo,
            gateLugarMaximo,
            gateLugarLatitud,
            gateLugarLongitud,
            gateLugarRadio,
            gateLugarVersion
        },
    };

    return await axiosPost(body, credentials);
};
