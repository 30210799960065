import React, { useState, useEffect, useCallback } from "react";

// recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import { rowSelected, userCredentials, routeInfo } from "recoilState/GlobalState";

//Services
import { accesoGet, zonaGet, provinciaGet } from "services/services-mia/miakey";

// utils
import XGrid from "components/UI/Grid/XGrid";
import { HeaderButton, Buttons } from "components";
import { Add, Cached, DeleteOutline, EditOutlined } from "@mui/icons-material";

import { DeleteModal, InsertModal, UpdateModal } from "./Actions";

// consts
import { accesos as titles } from "consts/titulos-de-tablas";
import { ACTIONS } from "consts/Actions";
import { Routes } from "consts/Routes-App";

// styles
import s from "styles/pages/Pages.module.scss"
import Modal from "components/UI/Modal/Modal";
import { useService } from "components/route/ServiceRoute";

export default function Accesos() {
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [modalDetails, setModalDetails] = useState({ isOpen: false });

    const [provincias, setProvincias] = useState([]);
    const [zonas, setZonas] = useState([]);

    const { service } = useService();

    const { row } = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);

    const updateList = useCallback(async () => {
        setIsLoading(true);
        setRows([]);
        const { result } = await accesoGet({}, credentials);
        setRows(result.map((item) => {
            const {
                gateLugarId,
                gateLugarBeaconCuenta,
                gateLugarCalle,
                gateLugarCasa,
                gateLugarDeviceCuenta,
                gateLugarEsDefault,
                gateLugarLatitud,
                gateLugarLongitud,
                gateLugarMaximo,
                gateLugarMinimo,
                gateLugarNombre,
                gateLugarPiso,
                gateLugarQRCuenta,
                gateLugarRadio,
                gateLugarUFH,
                gateLugarUID,
                gateLugarUnidad,
                gateLugarVersion,
                gateZonaId,
                gateZonaNombre,
                localidadId,
                localidadNombre,
                merchantId,
                merchantNombre,
                provinciaId,
                provinciaNombre
            } = item;

            return {
                id: gateLugarId,
                gateLugarId,
                gateLugarBeaconCuenta,
                gateLugarCalle,
                gateLugarCasa,
                gateLugarDeviceCuenta,
                gateLugarEsDefault,
                gateLugarLatitud,
                gateLugarLongitud,
                gateLugarMaximo,
                gateLugarMinimo,
                gateLugarNombre,
                gateLugarPiso,
                gateLugarQRCuenta,
                gateLugarRadio,
                gateLugarUFH,
                gateLugarUID,
                gateLugarUnidad,
                gateLugarVersion,
                gateZonaId,
                gateZonaNombre,
                localidadId,
                localidadNombre,
                merchantId,
                merchantNombre,
                provinciaId,
                provinciaNombre,
                latitud: gateLugarLatitud,
                longitud: gateLugarLongitud,
                [titles[0]]: gateLugarNombre,
                [titles[1]]: gateZonaNombre,
                [titles[2]]: provinciaNombre,
                [titles[3]]: localidadNombre,
                [titles[4]]: gateLugarCalle,
                [titles[5]]: gateLugarCasa,
                [titles[6]]: gateLugarPiso,
                [titles[7]]: gateLugarUnidad,
            };
        }));
        setIsLoading(false);
    }, [credentials]);

    useEffect(() => {
        setRoute(Routes.accesos)
    }, [setRoute])

    useEffect(() => {
        updateList()
    }, [service, updateList])

    useEffect(() => {
        if (Object.keys(credentials).length > 0 && service?.servicioCod) {
            (async () => {
                const { result: resultProvincias } = await provinciaGet({}, credentials);
                const { result: resultZonas } = await zonaGet({}, credentials)
                setProvincias(resultProvincias);
                setZonas(resultZonas);
            })();
        }
    }, [credentials, service])

    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };

    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };

    return (
        <main className={s.main}>
            <Modal onClose={closeModal} open={modalDetails.isOpen} title={modalDetails.title}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        acceso={row}
                        updateList={updateList}
                        closeModal={closeModal}
                        provincias={provincias}
                        zonas={zonas}
                    />
                )}
                {modalDetails.procede === ACTIONS.DELETE && (
                    <DeleteModal
                        acceso={row}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        updateList={updateList}
                        closeModal={closeModal}
                        provincias={provincias}
                        zonas={zonas}
                    />
                )}
            </Modal>
            <div className={s.actions}>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={updateList}
                        disabled={row.id}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Agregar Acceso"}
                        handleClick={handleClick}
                        data={{
                            title: "Agregar Acceso",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={row.id || !service.servicioPermiso === 'E'}
                    >
                        <Add />
                    </HeaderButton>
                    {/* <HeaderButton
                        text={"Detalle del Acceso"}
                        handleClick={handleClick}
                        data={{
                            title: "Detalle del Acceso",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!row.id || !service.servicioPermiso === 'E'}
                    >
                        <EditOutlined />
                    </HeaderButton> */}
                    <HeaderButton
                        text={"Editar Acceso"}
                        handleClick={handleClick}
                        data={{
                            title: "Editar Acceso",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!row.id || !service.servicioPermiso === 'E'}
                    >
                        <EditOutlined />
                    </HeaderButton>
                    <HeaderButton
                        text={"Eliminar Acceso"}
                        handleClick={handleClick}
                        data={{
                            title: "Eliminar Acceso",
                            procede: ACTIONS.DELETE,
                        }}
                        disabled={!row.id || !service.servicioPermiso === 'E'}
                    >
                        <DeleteOutline />
                    </HeaderButton>
                </Buttons>
            </div>
            <div className={s.container}>
                <XGrid
                    rows={rows}
                    titles={titles}
                    loading={isLoading}
                />
            </div>
        </main>
    );
}
