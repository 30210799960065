import React, { useEffect, useState } from "react";
import { Routes as Switch, Route, useLocation } from "react-router-dom";

import { useRecoilState, useSetRecoilState } from "recoil";
import { userInfo, userCredentials, miaServices } from "./recoilState/GlobalState";

import { getUserCredentials } from "services/getUserCredentials";
import { checkAuth } from "services/checkAuth";
import { usuarioMinimosGet } from "services/services-mia";

import ProtectedRoute from "./components/route/ProtectedRoute";
import { CircularIndeterminate, Header } from "components";
import SnackBar from "components/utils/snackbars/Snackbar";

import {
    Dashboard,
    Accesos,
    Horarios,
    Kioscos,
    Usuarios,
    Eventos,
    Login,
    UsuariosXServicio,
    DocumentType,
    Documents,
    UsuariosMIAKey,
    Zonas,
    UserDetailsDashboard
} from "./pages";

import { Routes as ROUTES } from "./consts/Routes-App";

import ServiceRoute from "components/route/ServiceRoute";
import { merchantLogoGet } from "services/services-mia/merchant";
import { loginServicioGet } from "services/services-mia/login";

import s from "./App.module.scss"

export default function App() {
    const [loading, setLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);

    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const setInfo = useSetRecoilState(userInfo);
    const setServices = useSetRecoilState(miaServices);

    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        (async () => {
            const newCredentials = await getUserCredentials();
            if (newCredentials) {
                setCredentials(newCredentials);
                const userData = await usuarioMinimosGet(newCredentials);
                const { result: merchantData } = await merchantLogoGet({}, newCredentials);
                setInfo({ ...merchantData[0], ...userData });
                const { result: servicesData } = await loginServicioGet({}, newCredentials);
                setServices(servicesData);
            }
            setIsAuth(checkAuth());
            setLoading(false);
        })();
    }, [setCredentials, setInfo, setServices]);

    useEffect(() => {
        if (loading) return;
        setIsAuth(checkAuth());
    }, [credentials, loading]);

    if (loading) return <CircularIndeterminate />;

    return (
        <div className={s.container}>
            <div className={setIsAuth.header}>
                {location.pathname !== ROUTES.login.route && <Header />}

            </div>
            <div className={s.body}>
                <SnackBar />

                <Switch>
                    <Route path={ROUTES.login.route} exact element={<Login />} />

                    <Route
                        element={
                            <ProtectedRoute
                                redirectProtectedRoute={ROUTES.login.route}
                                isAuth={isAuth}
                            />
                        }
                    >
                        <Route
                            path={ROUTES.dashboard.route}
                            exact
                            element={<Dashboard route={ROUTES.dashboard} />}
                        />
                        <Route
                            path={ROUTES.usuarios.route}
                            exact
                            element={<Usuarios route={ROUTES.usuarios} />}
                        />
                        <Route
                            path={"usuarios/:usuarioId/details"}
                            element={<UserDetailsDashboard />}
                        />
                        <Route
                            path="servicio/IDBASE"
                            element={<ServiceRoute servicioCod="IDBASE" redirectProtectedRoute={ROUTES.login.route} />}
                        >
                            <Route
                                path="usuarios"
                                element={<UsuariosXServicio />}
                            />
                        </Route>
                        <Route
                            path="servicio/SIGN"
                            element={<ServiceRoute servicioCod="SIGN" redirectProtectedRoute={ROUTES.login.route} />}
                        >
                            <Route
                                path="usuarios"
                                element={<UsuariosXServicio />}
                            />
                            <Route path="config">
                                <Route
                                    path="tipo_documento"
                                    exact
                                    element={<DocumentType />}
                                />
                            </Route>
                            <Route path="admin">
                                <Route
                                    path="documents"
                                    exact
                                    element={<Documents />}
                                />
                            </Route>
                        </Route>
                        <Route
                            path="servicio/HGATE"
                            element={<ServiceRoute servicioCod="HGATE" redirectProtectedRoute={ROUTES.login.route} />}
                        >
                            <Route
                                path="usuarios"
                                element={<UsuariosXServicio />}
                            />
                            <Route path="config">
                                <Route
                                    path="zonas"
                                    element={<Zonas />}
                                />
                                <Route
                                    path="accesos"
                                    element={<Accesos />}
                                />
                                <Route
                                    path="kioscos"
                                    element={<Kioscos />}
                                />
                                <Route
                                    path="horarios"
                                    element={<Horarios />}
                                />
                            </Route>
                            <Route path="admin">
                                <Route
                                    path="eventos"
                                    element={<Eventos />}
                                />
                                <Route
                                    path="usuarios"
                                    element={<UsuariosMIAKey />}
                                />
                            </Route>
                        </Route>
                        <Route
                            path="/*"
                            element={<Dashboard />}
                        />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}
