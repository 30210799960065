import React, { useEffect, useState } from "react";
import PersonIcon from '@mui/icons-material/Person';
import { Card } from "components";
import DoubleTooltip from "components/DoubleTooltip";

import styles from "./DashboardCard.module.scss";
import { formatNumber } from "services/Local-services";
/*const serviceUserStatus = [
  {
    statusCod: 'P',
    statusNombre: 'Pendientes',
  },
  {
    statusCod: 'A',
    statusNombre: 'Activos',
  },
  {
    statusCod: 'R',
    statusNombre: 'Rechazados',
  },
  {
    statusCod: 'S',
    statusNombre: 'Suspendidos',
  },
  {
    statusCod: 'B',
    statusNombre: 'Bajas',
  }
]*/

const serviceUserStatus = [
  {
    statusCod: 'Y',
    statusNombre: 'Con Onboard',
  },
  {
    statusCod: 'N',
    statusNombre: 'Sin Onboard',
  }
]

const serviceStatus = {
  'A': { color: 'orangeLink' },
  'C': { color: 'blueLink' },
  'N': { color: 'disabled' }
}

export default function DashboardCard({
  service = {}
}) {
  const [serviceDetails, setServiceDetails] = useState([])

  useEffect(() => {
    const newServiceDetails = []
    for (const serviceStatus of serviceUserStatus) {
      if (!service?.servicioDetalle?.length > 0) continue

      const totalUsuarios = service?.servicioDetalle
        .filter((serviceDetail) => serviceDetail?.conOnboard === serviceStatus.statusCod)
        .reduce((accumulator, serviceDetail) => accumulator + (serviceDetail?.totalUsuarios || 0), 0)

      if (!totalUsuarios > 0) continue

      newServiceDetails.push({
        statusNombre: serviceStatus.statusNombre,
        totalUsuarios
      })
    }
    setServiceDetails(newServiceDetails)
  }, [service]);

  const renderTooltipInfo = (
    <div className={styles['tooltip-info']}>
      <table className={styles.data}>
        <tbody>
          {serviceDetails.map((e, index) => (
            <tr key={index}>
              <td className={styles['data-key']}>{e.statusNombre}</td>
              <td className={styles['data-value']}>{formatNumber(e.totalUsuarios)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
  const isTooltipRequired = service.servicioAutorizado === "Y";
  return (

    isTooltipRequired ? (
      <DoubleTooltip
        firstTooltipText={service.servicioAutorizado === "Y" ? renderTooltipInfo : null}
      >
        <Card
          key={service.servicioId}
          data-cod={service.servicioCod}
          color={serviceStatus[service.servicioEstado]?.color ?? null}
        >
          <span>
            <img
              src={service.imagePathSERVICIO}
              alt={service.servicioNombre}
            />
          </span>
          <p>{service.servicioNombre}</p>
          {service.servicioAutorizado !== "N" &&
            <div className={styles['users-count']}>
              <PersonIcon /> {formatNumber(service.totalUsuarios)}
            </div>
          }
        </Card>
      </DoubleTooltip>
    ) :
      <Card
        key={service.servicioId}
        data-cod={service.servicioCod}
        color={serviceStatus[service.servicioEstado]?.color ?? null}
      >
        <span>
          <img
            src={service.imagePathSERVICIO}
            alt={service.servicioNombre}
          />
        </span>
        <p>{service.servicioNombre}</p>
      </Card>

  )
}