import axiosPost from "../axiosPost";

export const usuarioLugarHorarioGet = async ({
  usuarioId = null,
  gateUsuarioLugarHorarioId = null,
}, credentials) => {
  const body = {
    service: "GateUsuarioLugarHorarioGet",
    params: {
      merchantId: credentials.merchantId,
      usuarioId,
      gateUsuarioLugarHorarioId
    },
  };

  return await axiosPost(body, credentials);
};