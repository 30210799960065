import axiosPost from "../axiosPost";

export const horarioGet = async ({
    gateHorarioId = null,
}, credentials) => {
    const body = {
        service: "GateHorarioGet",
        params: {
            gateHorarioId,
            merchantId: credentials.merchantId,
        },
    };

    return await axiosPost(body, credentials);
};
