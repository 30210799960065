import React from "react";

import { Button } from "components";

import Estados from "pages/miakey/admin/estados/Estados";

export default function RangoModal({
    accesoDetailId, closeModal, service
}) {
    return (
        <>
            <Estados accesoDetailId={accesoDetailId} service={service} />
            <div className={"closeButtonContainer"}>
                <Button
                    className={"button"}
                    onClick={closeModal}
                >
                    Cerrar
                </Button>
            </div>

        </>
    );
}