import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#06132f",
        },
        secondary: {
            main: "#244C72",
        },
        white: {
            main: "#FFFFFF",
        },
        miaOrange: "#FF6D00",
    },
    components: {
        MuiSwitch: {
            styleOverrides: {
                "root": {
                    "&.Mui-checked": {
                        "color": "red"
                    }
                }
            },
        },
        MuiInputLabel: {
            shrink: {
                transform: "translate(0, 1.5px) scale(0.75)",
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "standard",
            },
            styleOverrides: {
                root: {
                    fontSize: "1.0rem",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: "1.0rem",
                    marginTop: "10px",
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: "1rem",
                },
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                li: {
                    color: "#63c3d1",
                    fontSize: "2rem",
                    fontWeight: 300,
                    padding: "2rem",
                },
                separator: {
                    fontSize: "2rem",
                },
            },
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant: "h4" },
                    style: {
                        fontSize: "1.5em",
                    },
                },
            ],
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: "1em",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FF6D00",
                    borderRadius: "10px",
                    color: "white",
                    height: "20px",
                },
            },
        },
    },
});
