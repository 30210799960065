import axiosPost from "../axiosPost";

export const horarioIns = async ({
    gateHorarioNombre,
    gateHorarioDescripcion = null
}, credentials) => {
    const body = {
        service: "GateHorarioIns",
        params: {
            merchantId: credentials.merchantId,
            gateHorarioNombre,
            gateHorarioDescripcion
        },
    };

    return await axiosPost(body, credentials);
};
