import React, { useState } from "react";

import { CircularIndeterminate, FormButton } from "components";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    modalData,
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { Form, Formik } from "formik";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { ACTIONS } from "consts/Actions";

import { RequestServiceActions } from "services/services-mia/RequestServiceActions";

import styles from "styles/UI/ModalForm.module.scss";

export default function DeleteModal({ updateList }) {
    const [isLoading, setIsLoading] = useState(false);
    const [inRequest, setInRequest] = useState(false);

    const row = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);

    const setModalDetails = useSetRecoilState(modalData);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const handleSubmit = async () => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "SBMDocumentoDel",
            params: {
                SBMDocumentoId: row.row.id
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.DELETE,
            setSnackbarInfo
        );
        await updateList();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    return (
        <Formik
            initialValues={{
                nombreDocumento: row.row.nombre,
            }}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles.column}>
                    <label className={styles.label}>
                        ¿Está seguro que desea eliminar este documento?
                    </label>

                    <FormikTextInput
                        disabled={true}
                        fullWidth={false}
                        id="nombreDocumento"
                        name="nombreDocumento"
                        type="text"
                        value={row.row.nombre}
                    />

                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Confirmar" }}
                    />

                    {isLoading && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
