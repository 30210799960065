import axiosPost from "../axiosPost";

export const zonaIns = async ({
    gateZonaNombre
}, credentials) => {
    const body = {
        service: "GateZonaIns",
        params: {
            merchantId: credentials.merchantId,
            gateZonaNombre,
        },
    };

    return await axiosPost(body, credentials);
};
