import axiosPost from "../axiosPost";

export const accesoIns = async ({
    gateZonaId,
    gateLugarNombre,
    gateLugarCalle,
    localidadId,
    gateLugarCasa,
    gateLugarUnidad,
    gateLugarPiso,
    gateLugarMinimo,
    gateLugarMaximo,
    gateLugarLatitud,
    gateLugarLongitud,
    gateLugarRadio,
}, credentials) => {
    const body = {
        service: "GateLugarIns",
        params: {
            merchantId: credentials.merchantId,
            gateZonaId,
            gateLugarNombre,
            gateLugarCalle,
            localidadId,
            gateLugarCasa,
            gateLugarUnidad,
            gateLugarPiso,
            gateLugarMinimo,
            gateLugarMaximo,
            gateLugarLatitud,
            gateLugarLongitud,
            gateLugarRadio,
        },
    };

    return await axiosPost(body, credentials);
};
