import axiosPost from "../axiosPost";

export const accesoGet = async ({
    gateLugarId = null,
}, credentials) => {
    const body = {
        service: "GateLugarGet",
        params: {
            gateLugarId,
            merchantId: credentials.merchantId,
        },
    };

    return await axiosPost(body, credentials);
};
