import moment from "moment";
import axiosPost from "./axiosPost";

export const merchantServicioUsuarioGet = async ({
  servicioHooliCod,
  usuarioId = null,
  rolCod = null
}, credentials) => {
  const body = {
    service: "MerchantServicioUsuarioGet",
    params: {
      merchantId: credentials.merchantId,
      servicioHooliCod,
      merchantServicioUsuarioFecha: moment().format("YYYY-MM-DD"),
      usuarioId,
      rolCod,
    },
  };

  return await axiosPost(body, credentials);
};