import axiosPost from "../axiosPost";

export const horarioUpd = async ({
    gateHorarioId,
    gateHorarioNombre,
    gateHorarioDescripcion = null,
    gateHorarioVersion
}, credentials) => {
    const body = {
        service: "GateHorarioUpd",
        params: {
            gateHorarioId,
            gateHorarioNombre,
            gateHorarioDescripcion,
            gateHorarioVersion
        },
    };

    return await axiosPost(body, credentials);
};
