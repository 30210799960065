import React, { useState } from "react";

import { FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { horarioRangoIns } from "services/services-mia/miakey";

const dias = [
    { diaCod: "LU", diaName: "Lunes" },
    { diaCod: "MA", diaName: "Martes" },
    { diaCod: "MI", diaName: "Miercoles" },
    { diaCod: "JU", diaName: "Jueves" },
    { diaCod: "VI", diaName: "Viernes" },
    { diaCod: "SA", diaName: "Sábado" },
    { diaCod: "DO", diaName: "Domingo" },
];

export default function InsertModal({ updateList, horarioId, closeModal }) {
    const setSelected = useSetRecoilState(rowSelected);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);

    const handleSubmit = async ({
        dia,
        entrada,
        salida
    }) => {
        setInRequest(true);

        const response = await horarioRangoIns({
            gateHorarioId: horarioId,
            gateHorarioRangoDia: dia,
            gateHorarioRangoEntrada: entrada,
            gateHorarioRangoSalida: salida,
        }, credentials);

        const functions = {
            setSnackbarInfo,
            setIsOpen: closeModal,
            setSelected,
            updateList,
        };

        handleResponse(
            response,
            "Rango Horario agregado exitosamente",
            functions
        );

        setInRequest(false);
    };

    return (
        <Formik
            initialValues={{
                dia: "LU",
                entrada: "09:00",
                salida: "18:00",
            }}
            validationSchema={
                yup.object({
                    dia: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required),
                    entrada: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required),
                    salida: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required),
                })
            }
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    <FormikSelectInput
                        name="dia"
                        labelText="Día"
                    >
                        {dias.map(({ diaCod, diaName }) => (
                            <option
                                key={diaCod}
                                data-id={diaCod}
                                value={diaCod}
                            >
                                {diaName}
                            </option>
                        ))}
                    </FormikSelectInput>
                    <FormikTextInput
                        fullWidth={true}
                        name="entrada"
                        labelText="Entrada"
                        type="time"
                    />
                    <FormikTextInput
                        fullWidth={true}
                        name="salida"
                        labelText="Salida"
                        type="time"
                    />
                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Agregar" }}
                        onCloseModal={closeModal}
                    />
                </div>
            </Form>
        </Formik>
    );
}
