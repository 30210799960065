import axiosPost from "../axiosPost";

export const horarioRangoGet = async ({
    gateHorarioRangoId = null,
    gateHorarioId
}, credentials) => {
    const body = {
        service: "GateHorarioRangoGet",
        params: {
            gateHorarioRangoId,
            gateHorarioId,
        },
    };

    return await axiosPost(body, credentials);
};
