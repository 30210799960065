import axiosPost from "../axiosPost";

export const horarioRangoIns = async ({
    gateHorarioId,
    gateHorarioRangoDia,
    gateHorarioRangoEntrada,
    gateHorarioRangoSalida
}, credentials) => {
    const body = {
        service: "GateHorarioRangoIns",
        params: {
            gateHorarioId,
            gateHorarioRangoDia,
            gateHorarioRangoEntrada,
            gateHorarioRangoSalida
        },
    };

    return await axiosPost(body, credentials);
};
