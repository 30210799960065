import React, { useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";
import * as XLSX from "xlsx";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";
import { usuariosXServicio as titles } from "consts/titulos-de-tablas";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { clienteUsuarioGet } from "services/services-mia";
import moment from "moment";

const onboardStatus = [
    {
        onboardStatusCod: '',
        onboardStatusNombre: 'Todos'
    },
    {
        onboardStatusCod: 'Y',
        onboardStatusNombre: 'Sí'
    },
    {
        onboardStatusCod: 'N',
        onboardStatusNombre: 'No'
    }
];

export default function ExportModal({ updateList, servicio, closeModal }) {
    const setSelected = useSetRecoilState(rowSelected);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);

    const handleSubmit = async ({
        desde,
        hasta,
        onboard
    }) => {
        setInRequest(true);

        const response = await clienteUsuarioGet({
            merchantId: credentials?.merchantId,
            tipoRelacion: "S",
            servicioCod: servicio?.servicioCod,
            fechaDesde: desde,
            fechaHasta: hasta,
            usuarioTieneApp: onboard || null,
        }, credentials);

        const result = response.map((usuario) => ({
            [titles[0].headerName]: usuario.usuarioNombreCompleto,
            [titles[1].headerName]: usuario.usuarioNroDeDocumentoX,
            [titles[2].headerName]: usuario.tipoDeDocumentoCod,
            [titles[3].headerName]: usuario.usuarioSexo,
            [titles[4].headerName]: usuario.usuarioFechaDeNacimiento,
            [titles[5].headerName]: usuario.usuarioMail,
            [titles[6].headerName]: usuario.rolNombre,
            [titles[7].headerName]: usuario.usuarioId === null ? 'NO' : 'SI',
            [titles[8].headerName]: usuario.usuarioFechaDeAlta ? moment(usuario.usuarioFechaDeAlta).format("YYYY-MM-DD").toString() : null,
            [titles[9].headerName]: usuario.relacionStatus,
            [titles[10].headerName]: usuario.usuarioFecha,
        }));

        console.info('rows export: ', result.length)
        try {
            const worksheet = XLSX.utils.json_to_sheet(result);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, `Usuarios-${servicio.servicioNombre}.xlsx`, { compression: true });
        } catch (e) {
            console.error(e)
            return
        }

        const functions = {
            setSnackbarInfo,
            setInRequest,
            setIsOpen: closeModal,
            setSelected,
            updateList,
        };

        handleResponse(
            { status: { code: 1 } },
            "Exportado exitosamente",
            functions
        );
    };

    return (
        <Formik
            initialValues={{
                desde: new Date().toISOString().substring(0, 10),
                hasta: new Date().toISOString().substring(0, 10),
                onboard: '',
            }}
            validationSchema={
                yup.object({
                    desde: yup.date(FORM_ERROR_MESSAGES.date)
                        .required(FORM_ERROR_MESSAGES.required)
                        .max(yup.ref("hasta"), "No puede ser posterior a fecha hasta"),
                    hasta: yup.date(FORM_ERROR_MESSAGES.date)
                        .required(FORM_ERROR_MESSAGES.required)
                })
            }
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles['column-sm']}>
                    <FormikSelectInput
                        fullWidth={true}
                        name="onboard"
                        labelText="Onboard"
                    >
                        {onboardStatus.map(({ onboardStatusCod, onboardStatusNombre }) => (
                            <option
                                key={onboardStatusCod || 'Todos'}
                                data-id={onboardStatusCod}
                                value={onboardStatusCod}
                            >
                                {onboardStatusNombre}
                            </option>
                        ))}
                    </FormikSelectInput>
                    <FormikTextInput
                        fullWidth={true}
                        name="desde"
                        labelText={"Desde"}
                        type="date"
                    />

                    <FormikTextInput
                        fullWidth={true}
                        name="hasta"
                        labelText={"Hasta"}
                        type="date"
                    />

                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Exportar" }}
                        onCloseModal={closeModal}
                    />

                    {inRequest && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
