import React, { useCallback, useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton, Mapa } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { accesoUpd, localidadGet } from "services/services-mia/miakey";
import ModalMapa from "components/utils/mapa/ModalMapa";
import { Grid } from "@mui/material";
import Modal from "components/UI/Modal/Modal";

export default function UpdateModal({ updateList, acceso, closeModal, zonas = [], provincias = [] }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [localidades, setLocalidades] = useState([]);
    const [modalMapaOpen, setModalMapaOpen] = useState(false);
    const [coordenadas, setCoordenadas] = useState({});

    const setSelected = useSetRecoilState(rowSelected);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const formikRef = useRef();

    const getLocalidades = useCallback(async (provinciaId, localidadId) => {
        if (!provinciaId) return;
        setIsLoading(true);
        const { result, status } = await localidadGet({
            provinciaId: provinciaId
        }, credentials);
        if (status.code !== 1) {
            setIsLoading(false);
            return;
        }
        const currentLocalidad = localidadId
            ? result.find((localidad) => (localidad.localidadId === localidadId))
            : result[0];
        setLocalidades(result)
        selectLocalidad(currentLocalidad ?? {})
        setIsLoading(false);
    }, [credentials])

    useEffect(() => {
        if (Object.keys(credentials).length === 0) return;
        (async () => {
            setCoordenadas({
                latitud: acceso.gateLugarLatitud ?? -34.6036844,
                longitud: acceso.gateLugarLongitud ?? -58.3815591,
            })
            await getLocalidades(acceso.provinciaId, acceso.localidadId);
        })()
    }, [credentials, acceso, getLocalidades]);

    const selectProvincia = ({ provinciaId, provinciaNombre }) => {
        formikRef.current.setFieldValue("provincia", provinciaId)
        formikRef.current.setFieldValue("provinciaNombre", provinciaNombre)
    }

    const selectLocalidad = ({ localidadId, localidadNombre }) => {
        formikRef.current.setFieldValue("localidad", localidadId)
        formikRef.current.setFieldValue("localidadNombre", localidadNombre)
    }

    const handleChangeProvincia = (event) => {
        const provinciaId = Number(event.target.value);
        const currentProvincia = provincias.find((provincia) => (provincia.provinciaId === provinciaId))
        selectProvincia(currentProvincia ?? {})
        getLocalidades(provinciaId);
    }

    const handleChangeLocalidad = (event) => {
        const localidadId = Number(event.target.value);
        const currentLocalidad = localidades.find((localidad) => (localidad.localidadId === localidadId))
        selectLocalidad(currentLocalidad ?? {})
    }

    const handleSetCoordenadas = ({ latitud, longitud }) => {
        setCoordenadas({
            latitud,
            longitud
        })
    }

    const handleSubmit = async ({
        zona,
        nombre,
        calle,
        localidad,
        numero,
        unidad,
        piso,
    }) => {
        setInRequest(true);

        const response = await accesoUpd({
            gateLugarId: acceso.gateLugarId,
            gateZonaId: zona,
            gateLugarNombre: nombre,
            gateLugarCalle: calle,
            localidadId: localidad,
            gateLugarCasa: numero,
            gateLugarUnidad: unidad,
            gateLugarPiso: piso,
            gateLugarMinimo: 0,
            gateLugarMaximo: 99999,
            gateLugarLatitud: coordenadas.latitud.toFixed(6),
            gateLugarLongitud: coordenadas.longitud.toFixed(6),
            gateLugarRadio: 50,
            gateLugarVersion: acceso.gateLugarVersion,
        }, credentials);

        const functions = {
            setSnackbarInfo,
            setIsOpen: closeModal,
            setSelected,
            updateList,
        };

        handleResponse(
            response,
            "Acceso modificado exitosamente",
            functions
        );

        setInRequest(false);
    };

    const closeModalMapa = () => {
        setModalMapaOpen(false)
    }

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                id: acceso.id,
                zona: acceso.gateZonaId,
                nombre: acceso.gateLugarNombre ?? "",
                provincia: acceso.provinciaId,
                provinciaNombre: acceso.provinciaNombre,
                localidad: acceso.localidadId,
                localidadNombre: acceso.localidadNombre,
                calle: acceso.gateLugarCalle ?? "",
                numero: acceso.gateLugarCasa ?? "",
                piso: acceso.gateLugarPiso ?? "",
                unidad: acceso.gateLugarUnidad ?? "",
            }}
            validationSchema={
                yup.object({
                    zona: yup.number(FORM_ERROR_MESSAGES.number)
                        .required(FORM_ERROR_MESSAGES.required),
                    nombre: yup.string(FORM_ERROR_MESSAGES.text)
                        .min(2, "Debe tener minimo 2 caracteres")
                        .max(45, "Debe tener un maximo de 45 caracteres")
                        .matches(
                            /^[A-Za-z0-9][A-Za-z0-9\s]*[A-Za-z0-9]$/,
                            "Debe tener solo letras y/o números"
                        )
                        .required(FORM_ERROR_MESSAGES.required),
                    calle: yup.string(FORM_ERROR_MESSAGES.text)
                        .min(2, "Debe tener minimo 2 caracteres")
                        .max(45, "Debe tener un maximo de 45 caracteres")
                        .matches(
                            /^[A-Za-z0-9][A-Za-z0-9\s]*[A-Za-z0-9]$/,
                            "Debe tener solo letras y/o números"
                        )
                        .required(FORM_ERROR_MESSAGES.required),
                    numero: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required),
                })
            }
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <Modal onClose={closeModalMapa} open={modalMapaOpen} title="Seleccionar coordenadas">
                    {modalMapaOpen && <ModalMapa
                        closeModal={closeModalMapa}
                        formik={formikRef?.current}
                        latitud={coordenadas.latitud}
                        longitud={coordenadas.longitud}
                        calle={formikRef?.current?.values?.calle}
                        numero={formikRef?.current?.values?.numero}
                        localidad={formikRef?.current?.values?.localidadNombre}
                        provincia={formikRef?.current?.values?.provinciaNombre}
                        onSetCoordenadas={handleSetCoordenadas}
                    />}
                </Modal>
                <div className={styles.column}>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <FormikSelectInput
                                name="zona"
                                labelText="Zona"
                            >
                                {zonas.map(({ gateZonaId, gateZonaNombre }) => (
                                    <option
                                        key={gateZonaId}
                                        data-id={gateZonaId}
                                        value={gateZonaId}
                                    >
                                        {gateZonaNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                        </Grid>
                        <Grid item md={6}>
                            <FormikTextInput
                                name="nombre"
                                labelText={"Nombre"}
                                placeholder="Ingrese nombre"
                            />
                        </Grid>
                        <Grid item md={6}>
                            <FormikSelectInput
                                name="provincia"
                                labelText="Provincia"
                                onChange={handleChangeProvincia}
                            >
                                {provincias.map(({ provinciaId, provinciaNombre }) => (
                                    <option
                                        key={provinciaId}
                                        data-id={provinciaId}
                                        value={provinciaId}
                                    >
                                        {provinciaNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                        </Grid>
                        <Grid item md={6}>
                            <FormikSelectInput
                                name="localidad"
                                labelText="Localidad"
                                onChange={handleChangeLocalidad}
                            >
                                {localidades.map(({ localidadId, localidadNombre }) => (
                                    <option
                                        key={localidadId}
                                        data-id={localidadId}
                                        value={localidadId}
                                    >
                                        {localidadNombre}
                                    </option>
                                ))}
                            </FormikSelectInput>
                        </Grid>
                        <Grid item md={6}>
                            {!modalMapaOpen && (
                                <Mapa
                                    lat={coordenadas.latitud}
                                    lng={coordenadas.longitud}
                                />
                            )}
                            <button
                                type="button"
                                className="map-btn"
                                onClick={() => setModalMapaOpen(true)}
                            >
                                Buscar coordenadas
                            </button>
                        </Grid>
                        <Grid item md={6}>
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <FormikTextInput
                                        name="calle"
                                        labelText={"Calle"}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <FormikTextInput
                                        name="numero"
                                        labelText={"Número"}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <FormikTextInput
                                        name="piso"
                                        labelText={"Piso"}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <FormikTextInput
                                        name="unidad"
                                        labelText={"Unidad"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <FormButton
                        inRequest={inRequest || isLoading}
                        newFormData={{ button: "Guardar cambios" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
