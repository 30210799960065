import React, { useState, useEffect, useCallback } from "react";

// recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userCredentials, routeInfo } from "recoilState/GlobalState";

//Services
import { kioscoGet } from "services/services-mia/miakey";

// utils
import XGrid from "components/UI/Grid/XGrid";
import { HeaderButton, Buttons } from "components";
import Modal from "components/UI/Modal/Modal";
import { Cached, EditOutlined, SearchOutlined } from "@mui/icons-material";

import { DetailModal, UpdateModal } from "./Actions";

// consts
import { kioscos as titles } from "consts/titulos-de-tablas";
import { ACTIONS } from "consts/Actions";
import { Routes } from "consts/Routes-App";

// styles
import s from "styles/pages/Pages.module.scss"
import { useService } from "components/route/ServiceRoute";

export default function Kioscos() {
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })

    const { service } = useService();

    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);

    const updateList = useCallback(async () => {
        setIsLoading(true);
        setRows([]);
        const { result } = await kioscoGet({}, credentials);
        setRows(result.map((item) => {
            const {
                gateDeviceId,
                gateDeviceIdentificacion,
                gateDeviceMAC,
                gateDeviceMarca,
                gateDeviceModelo,
                gateDeviceSerie,
                gateDeviceFechaCompra,
                gateDeviceVersion,
                gateLugarNombre,
                gateDeviceESTipo,
                gateDeviceStatus,
                gateLugarId,
                gateZonaId,
                gateZonaNombre,
                gateDeviceFechaDesde,
                gateDeviceFechaHasta,
            } = item;

            return {
                id: gateDeviceId,
                gateDeviceId,
                gateDeviceIdentificacion,
                gateDeviceMAC,
                gateDeviceMarca,
                gateDeviceModelo,
                gateDeviceSerie,
                gateDeviceFechaCompra,
                gateDeviceVersion,
                gateLugarNombre,
                gateDeviceESTipo,
                gateDeviceStatus,
                gateLugarId,
                gateZonaId,
                gateZonaNombre,
                gateDeviceFechaDesde,
                gateDeviceFechaHasta,
                [titles[0]]: gateZonaNombre,
                [titles[1]]: gateLugarNombre,
                [titles[2]]: gateDeviceIdentificacion,
                [titles[3]]: gateDeviceMarca,
                [titles[4]]: gateDeviceModelo,
                [titles[5]]: gateDeviceSerie,
                [titles[6]]: gateDeviceMAC,
                [titles[7]]:
                    gateDeviceESTipo === "E"
                        ? "Entrada"
                        : gateDeviceESTipo === "S"
                            ? "Salida"
                            : "Entrada / Salida",
                [titles[8]]:
                    gateDeviceStatus === "A"
                        ? "Alta"
                        : gateDeviceStatus === "B"
                            ? "Baja"
                            : "Suspendido",
                [titles[9]]: gateDeviceFechaDesde,
            }
        }));
        setIsLoading(false);
    }, [credentials]);

    useEffect(() => {
        setRoute(Routes.kioscos)
    }, [setRoute])

    useEffect(() => {
        updateList()
    }, [service, updateList])

    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };

    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };

    return (
        <main className={s.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        kiosco={rowSelected.row}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DETAIL && (
                    <DetailModal
                        kioscoId={rowSelected.row.gateDeviceId}
                        service={service}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s.actions}>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={updateList}
                        disabled={rowSelected.row.id}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Ver detalle"}
                        handleClick={handleClick}
                        data={{
                            title: "Detalle de Kiosco",
                            procede: ACTIONS.DETAIL
                        }}
                        disabled={!rowSelected.row.id || !service.servicioPermiso === 'E'}
                    >
                        <SearchOutlined />
                    </HeaderButton>
                    <HeaderButton
                        text={"Editar Kiosco"}
                        handleClick={handleClick}
                        data={{
                            title: "Editar Kiosco",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!rowSelected.row.id || !service.servicioPermiso === 'E'}
                    >
                        <EditOutlined />
                    </HeaderButton>
                </Buttons>
            </div>
            <div className={s.container}>
                <XGrid
                    rows={rows}
                    titles={titles}
                    loading={isLoading}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    );
}
