import axiosPost from "./axiosPost";

export const RequestServiceGet = async (
    body,
    credentials,
    setSnackbarInfo = () => {}
) => {
    try {
        const result = await axiosPost(body, credentials);

        if (
            result?.status?.action === "E" &&
            (result?.status?.errcode || result?.status?.errmsg)
        ) {
            setSnackbarInfo({
                message: result.status.errmsg,
                severity: "error",
                open: true,
            });
        }

        return result.result;
    } catch (e) {
        setSnackbarInfo({
            message: e.errmsg,
            severity: "error",
            open: true,
        });
    }
};
