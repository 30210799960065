import axiosPost from "../axiosPost";

export const lugarDeviceUpd = async ({
    gateLugarDeviceId,
    gateLugarId,
    gateLugarDeviceFecha,
    gateLugarDeviceStatus,
    gateLugarDeviceTipo,
    gateDeviceRequiereEntrada,
    gateDeviceRequiereSalida,
    gateLugarDeviceVersion
}, credentials) => {
    const body = {
        service: "GateDeviceUpd",
        params: {
            gateLugarDeviceId,
            gateLugarId,
            gateLugarDeviceFecha,
            gateLugarDeviceStatus,
            gateLugarDeviceTipo,
            gateDeviceRequiereEntrada,
            gateDeviceRequiereSalida,
            gateLugarDeviceVersion
        },
    };

    return await axiosPost(body, credentials);
};
