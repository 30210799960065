import React, { useCallback, useEffect, useState } from "react";
import styles from "./Dashboard.module.scss";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    miaServices,
    routeInfo,
    userCredentials,
} from "recoilState/GlobalState";

import { Card, CircularIndeterminate } from "components";

import * as iconServicios from "assets/icons/servicios";
import { FiberManualRecord } from "@mui/icons-material";
import { Routes } from "consts/Routes-App";
import { servicioDashboardGet } from "services/services-mia/servicioDashboardGet";
import DashboardCard from "./components/DashboardCard";

const selectStyleCard = (value) => {
    switch (value) {
        case "A": {
            return "orangeLink";
        }
        case "C": {
            return "blueLink"
        }
        case "N": {
            return "disabled"
        }
        default: {
            return null;
        }
    }
}

export default function Dashboard() {
    const [serviceDetails, setServiceDetails] = useState({})
    const credentials = useRecoilValue(userCredentials);
    const services = useRecoilValue(miaServices);
    const setRoute = useSetRecoilState(routeInfo);

    const updateServiceDetails = useCallback(async () => {
        const { result } = await servicioDashboardGet({}, credentials);

        const newServiceDetail = {};
        for (const service of result || []) {
            if (!newServiceDetail[service.servicioCod]) newServiceDetail[service.servicioCod] = []
            newServiceDetail[service.servicioCod].push({
                merchantServicioUsuarioStatus: service.merchantServicioUsuarioStatus,
                conOnboard: service.conOnboard,
                totalUsuarios: service.totalUsuarios
            })
        }
        setServiceDetails(newServiceDetail);
    }, [credentials]);

    useEffect(() => {
        setRoute(Routes.dashboard)
    }, [setRoute])

    useEffect(() => {
        if (services.length > 0) {
            updateServiceDetails()
        }
    }, [services.length, updateServiceDetails])

    return (
        <main className={styles.main}>
            <section className={styles.section}>
                <span className={styles.title}>
                    <h1 className={styles.welcome}>
                        ¡Hola {credentials.merchantNombre}!
                    </h1>

                    <p className={styles.phrase}>Gestioná todas tus aplicaciones de MIA en un sólo lugar.</p>
                </span>

                <div className={styles.serviceContainer}>
                    {services.length ? (
                        <>
                            {
                                services?.map(
                                    ({
                                        servicioId,
                                        servicioCod,
                                        servicioNombre,
                                        servicioAutorizado,
                                        servicioEstado
                                    }) => (
                                        <DashboardCard
                                            key={servicioCod}
                                            service={{
                                                servicioId,
                                                servicioCod,
                                                imagePathSERVICIO: iconServicios[servicioCod],
                                                servicioNombre,
                                                servicioAutorizado,
                                                servicioEstado,
                                                servicioDetalle: serviceDetails[servicioCod] || [],
                                                totalUsuarios: serviceDetails[servicioCod]?.reduce((accumulator, currentValue) => accumulator + currentValue.totalUsuarios, 0) || 0
                                            }}
                                        />
                                    )
                                )
                            }
                            <Card
                                key={"USUARIOS"}
                                color={selectStyleCard("C")}
                            >
                                <span>
                                    <img
                                        src={iconServicios.USUARIOS}
                                        alt={"usuarios_globales"}
                                    />
                                </span>
                                <p>{"Usuarios Globales"}</p>
                            </Card>

                        </>
                    ) : (
                        <CircularIndeterminate />
                    )}
                </div>
                <div className={styles.containerInfo}>
                    <div>
                        <FiberManualRecord className={styles.iconsNoContratado} />
                        <div>Servicio disponible para contratar</div>
                    </div>
                    <div>
                        <FiberManualRecord className={styles.iconsAutorizado} />
                        <div>Servicio habilitado para ti</div>
                    </div>
                    <div>
                        <FiberManualRecord className={styles.iconsContratado} />
                        <div>Servicio contratado</div>
                    </div>
                </div>
                <div className={styles.help}>¿Necesitas ayuda?</div>
            </section>
        </main>
    );
}
