import axiosPost from "../axiosPost";

export const accesoDel = async ({
    gateLugarId,
}, credentials) => {
    const body = {
        service: "GateLugarDel",
        params: {
            gateLugarId,
        },
    };

    return await axiosPost(body, credentials);
};
