import React, { useState, useEffect, useCallback } from "react";

// recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userCredentials, routeInfo } from "recoilState/GlobalState";

//Services
import { usuarioLugarHorarioGet } from "services/services-mia/miakey";

// utils
import XGrid from "components/UI/Grid/XGrid";
import { HeaderButton, Buttons } from "components";
import { Add, Cached, TableChartOutlined, SearchOutlined } from "@mui/icons-material";

import Modal from "components/UI/Modal/Modal";
import { EstadoModal, InsertModal, RangoModal } from "./Actions";

// consts
import { dashboardAccesos as titles } from "consts/titulos-de-tablas";
import { ACTIONS } from "consts/Actions";
import { Routes } from "consts/Routes-App";

// styles
import s from "styles/pages/Pages.module.scss"

export default function AccesoDetails({
    usuarioId,
    service
}) {
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })

    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);

    const updateList = useCallback(async () => {
        setIsLoading(true);
        setRows([]);
        const { result } = await usuarioLugarHorarioGet({
            usuarioId
        }, credentials);
        setRows(result.map((item) => {
            const {
                gateUsuarioLugarHorarioId,
                usuarioNombres,
                usuarioApellidos,
                gateUsuarioLugarHorarioStatus,
                gateUsuarioLugarHorarioFecha,
                gateHorarioId,
                gateHorarioNombre,
                gateHorarioDescripcion,
                gateZonaId,
                gateLugarId,
                gateLugarNombre,
                gateZonaNombre,
                gateUsuarioLugarHorarioVersion,
            } = item;

            return {
                id: gateUsuarioLugarHorarioId,
                gateUsuarioLugarHorarioId,
                usuarioNombres,
                usuarioApellidos,
                gateUsuarioLugarHorarioStatus,
                gateUsuarioLugarHorarioFecha,
                gateHorarioId,
                gateHorarioNombre,
                gateHorarioDescripcion,
                gateZonaId,
                gateLugarId,
                gateLugarNombre,
                gateZonaNombre,
                gateUsuarioLugarHorarioVersion,
                usuario: `${usuarioNombres} ${usuarioApellidos}`,
                [titles[0]]: gateZonaNombre,
                [titles[1]]: gateLugarNombre,
                [titles[2]]: gateHorarioNombre,
                [titles[3]]: gateHorarioDescripcion,
                [titles[4]]: gateUsuarioLugarHorarioStatus === "A" ? "Alta" : "Baja",
                [titles[5]]: gateUsuarioLugarHorarioFecha,
            }
        }));
        setIsLoading(false);
    }, [credentials, usuarioId]);

    useEffect(() => {
        setRoute(Routes.accesos)
    }, [setRoute])

    useEffect(() => {
        updateList()
    }, [service, updateList]);

    const handleClick = ({ procede, title }) => {
        setModalDetails({ title, procede, isOpen: true });
    };

    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };

    return (
        <main className={s.main}>
            <Modal onClose={closeModal} open={modalDetails.isOpen} title={modalDetails.title}>
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        usuarioId={usuarioId}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DETAIL && (
                    <RangoModal
                        horarioId={rowSelected.row.gateHorarioId}
                        service={service}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.GET && (
                    <EstadoModal
                        accesoDetailId={rowSelected.row.gateUsuarioLugarHorarioId}
                        service={service}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s.actions}>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={updateList}
                        disabled={rowSelected.row.id}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Histórico de Estados"}
                        handleClick={handleClick}
                        data={{
                            title: `Detalle - ${rowSelected.row.usuario} - ${rowSelected.row.gateLugarNombre} - ${rowSelected.row.gateHorarioNombre}`,
                            procede: ACTIONS.GET
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <TableChartOutlined />
                    </HeaderButton>
                    <HeaderButton
                        text={"Detalles de Horario"}
                        handleClick={handleClick}
                        data={{
                            title: "Detalles de Horario",
                            procede: ACTIONS.DETAIL
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <SearchOutlined />
                    </HeaderButton>
                    <HeaderButton
                        text={"Agregar Configuración"}
                        handleClick={handleClick}
                        data={{
                            title: "Agregar Configuración",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id || !service.servicioPermiso === 'E'}
                    >
                        <Add />
                    </HeaderButton>
                </Buttons>
            </div>
            <div className={s.container}>
                <XGrid
                    rows={rows}
                    titles={titles}
                    loading={isLoading}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    );
}
