import axiosPost from "../axiosPost";

export const lugarDeviceIns = async ({
    gateDeviceId,
    gateLugarId,
    gateLugarDeviceFecha,
    gateLugarDeviceStatus,
    gateLugarDeviceTipo,
    gateDeviceRequiereEntrada,
    gateDeviceRequiereSalida,
}, credentials) => {
    const body = {
        service: "GateDeviceIns",
        params: {
            gateDeviceId,
            gateLugarId,
            gateLugarDeviceFecha,
            gateLugarDeviceStatus,
            gateLugarDeviceTipo,
            gateDeviceRequiereEntrada,
            gateDeviceRequiereSalida,
        },
    };

    return await axiosPost(body, credentials);
};
