import axiosPost from "../axiosPost";

export const lugarDeviceDel = async ({
    gateLugarDeviceId,
}, credentials) => {
    const body = {
        service: "GateLugarDeviceDel",
        params: {
            gateLugarDeviceId,
        },
    };

    return await axiosPost(body, credentials);
};
