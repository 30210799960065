import React from "react";
import FormActions from "../FormAction/FormActions";
import { RequestServiceActions } from "services/services-mia/RequestServiceActions";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalData, rowSelected, snackbarData, userCredentials } from "recoilState/GlobalState";
import { ACTIONS } from "consts/Actions";

export default function UpdateModal({ updateList }) {
    const credentials = useRecoilValue(userCredentials);
    const setModalDetails = useSetRecoilState(modalData);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const xgridRowSelected = useRecoilValue(rowSelected);
    console.log("Esto es row selected", xgridRowSelected);
    const handleSubmit = async (values) => {
        try {
            const body = {
                service: "SBMDocumentoUpd",
                params: {
                    SBMDocumentoId: xgridRowSelected.row.id,
                    SBMTipoDocumentoId: values.tipoDocumento,
                    SBMDocumentoFecha: values.fechaDocumento,
                    SBMDocumentoNombre: values.nombreDocumento,
                    SBMDocumentoDescripcion: values.descripcion,
                    SBMDocumentoRequiereFirma: values.requiereFirma === true ? "Y" : "N",
                    SBMDocumentoFirmantesExternos: values.cantFirmantesExternos,
                    SBMDocumentoFirmantesInternos: values.cantFirmantesInternos,
                    SBMDocumentoVersion: xgridRowSelected.row.version
                }

            }
            await RequestServiceActions(body, credentials, ACTIONS.UPDATE, setSnackbarInfo);
            setModalDetails({ isOpen: false });
            await updateList();
        } catch (e) {

        }
    }
    return (
        <section>
            <main>
                <FormActions handleSubmit={handleSubmit} initialValues={{
                    requiereFirma: xgridRowSelected.row.requiereFirma === "Y" ? true : false,
                    tipoDocumento: xgridRowSelected.row.tipoDocumentoId,
                    nombreDocumento: xgridRowSelected.row.nombre,
                    fechaDocumento: xgridRowSelected.row.fechaDocumento,
                    cantFirmantesInternos: xgridRowSelected.row.firmantesInternos,
                    cantFirmantesExternos: xgridRowSelected.row.firmantesExternos,
                    descripcion: xgridRowSelected.row.descripcion


                }} />
            </main>
        </section>
    );
}