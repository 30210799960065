import axiosPost from "../axiosPost";

export const loginServicioGet = async ({
    merchantId,
}, credentials) => {
    const body = {
        service: "MIAServicioGet",
        params: {
            merchantId: credentials.merchantId ?? merchantId,
        },
    };

    return await axiosPost(body, credentials);
};
