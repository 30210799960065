import axiosPost from "../axiosPost";

export const kioscoGet = async ({
    gateDeviceId = null
}, credentials) => {
    const body = {
        service: "GateDeviceGet",
        params: {
            merchantId: credentials.merchantId,
            gateDeviceId,
        },
    };

    return await axiosPost(body, credentials);
};
