import React from "react";
import classes from "./Card.module.scss";

export default function Card({
    color,
    children,
    isDisabled = false,
}) {
    return (
        <div
            className={`${classes.card} ${classes[color]} ${isDisabled ? classes.disabled : ""}`}
            aria-disabled={isDisabled}
            data-testid="card"
        >
            <div className={classes.containerChildren}>{children}</div>
        </div>
    );
}
