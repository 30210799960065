import React, { useState, useEffect, useCallback } from "react";

// recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userCredentials, routeInfo } from "recoilState/GlobalState";

//Services
import { horarioGet } from "services/services-mia/miakey";

// utils
import XGrid from "components/UI/Grid/XGrid";
import { HeaderButton, Buttons } from "components";
import { Add, Cached, DeleteOutline, EditOutlined, SearchOutlined } from "@mui/icons-material";

import { DeleteModal, InsertModal, UpdateModal, RangoModal } from "./Actions";

// consts
import { horarios as titles } from "consts/titulos-de-tablas";
import { ACTIONS } from "consts/Actions";
import { Routes } from "consts/Routes-App";

// styles
import s from "styles/pages/Pages.module.scss"

import { useService } from "components/route/ServiceRoute";
import Modal from "components/UI/Modal/Modal";

export default function Horarios() {
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })

    const { service } = useService();

    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);

    const updateList = useCallback(async () => {
        setIsLoading(true);
        setRows([]);
        const { result } = await horarioGet({}, credentials);
        setRows(result.map((item) => {
            const {
                gateHorarioId,
                gateHorarioNombre,
                gateHorarioDescripcion,
                gateHorarioVersion,
            } = item;

            return {
                id: gateHorarioId,
                gateHorarioId,
                gateHorarioNombre,
                gateHorarioDescripcion,
                gateHorarioVersion,
                [titles[0]]: gateHorarioNombre,
                [titles[1]]: gateHorarioDescripcion,
            }
        }));
        setIsLoading(false);
    }, [credentials]);

    useEffect(() => {
        setRoute(Routes.horarios)
    }, [setRoute])

    useEffect(() => {
        updateList()
    }, [service, updateList])

    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };

    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };

    return (
        <main className={s.main}>
            <Modal onClose={closeModal} open={modalDetails.isOpen} title={modalDetails.title}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        horario={rowSelected.row}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DELETE && (
                    <DeleteModal
                        horario={rowSelected.row}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === "EditRango" && (
                    <RangoModal
                        horarioId={rowSelected.row.gateHorarioId}
                        service={service}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s.actions}>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={updateList}
                        disabled={rowSelected.row.id}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Ver Rangos Horario"}
                        handleClick={handleClick}
                        data={{
                            title: "Rangos Horario",
                            procede: "EditRango"
                        }}
                        disabled={!rowSelected.row.id || !service.servicioPermiso === 'E'}
                    >
                        <SearchOutlined />
                    </HeaderButton>
                    <HeaderButton
                        text={"Agregar Horario"}
                        handleClick={handleClick}
                        data={{
                            title: "Agregar Horario",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id || !service.servicioPermiso === 'E'}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Editar Horario"}
                        handleClick={handleClick}
                        data={{
                            title: "Editar Horario",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!rowSelected.row.id || !service.servicioPermiso === 'E'}
                    >
                        <EditOutlined />
                    </HeaderButton>
                    <HeaderButton
                        text={"Eliminar Horario"}
                        handleClick={handleClick}
                        data={{
                            title: "Eliminar Horario",
                            procede: ACTIONS.DELETE,
                        }}
                        disabled={!rowSelected.row.id || !service.servicioPermiso === 'E'}
                    >
                        <DeleteOutline />
                    </HeaderButton>
                </Buttons>
            </div>
            <div className={s.container}>
                <XGrid
                    rows={rows}
                    titles={titles}
                    loading={isLoading}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    );
}
