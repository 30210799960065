import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useDropzone } from 'react-dropzone';
import classes from "./index.module.scss";
import { RequestServiceGet } from "services/services-mia/RequestServiceGet";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalData, rowSelected, snackbarData, userCredentials } from "recoilState/GlobalState";
import axios from "axios";
import { IconUpload } from "assets/icons";
import { CircularIndeterminate } from "components";
export default function FileUpload() {
    //Local states
    const [file, setFile] = useState(null);
    const [errorFile, setErrorFile] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    //Global states
    const xgridRowSelected = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const setModal = useSetRecoilState(modalData)


    const onDrop = async (acceptedFiles, rejectedFiles) => {

        if (rejectedFiles.length > 0) {
            return setErrorFile('Solo se permiten archivos PDF.');
        }
        if (acceptedFiles.length > 0) {
            const selectedFile = acceptedFiles[0];

            const data = await getSessionUri(selectedFile.path, selectedFile.size);
            console.log("Response getSessionUri", data);
            if (data && data.uri && data.chunks) {
                const { uri, chunks } = data;
                setFile(selectedFile);
                await uploadFileInChunks(selectedFile, uri, chunks);
            }
        }
    };


    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            "application/pdf": [".pdf"]
        },
        onDrop
    });

    const files = acceptedFiles.map(file => (
        <div key={file.path} className={classes.fileCharged}>
            <h1>{file.path}</h1>
            <p>{bytesToMegabytes(file.size)} MB  </p>
        </div>
    ));
    function bytesToMegabytes(bytes) {
        const megabytes = bytes / (1024 * 1024);
        return megabytes < 1 ? megabytes.toFixed(1) : Math.floor(megabytes);
    }

    const uploadFileInChunks = async (file, url, chunks, carga) => {
        const chunkSize = Math.ceil(file.size / chunks);
        let start = 0;
        let end = chunkSize;
        let response = null;
        setIsLoading(true);
        for (let i = 0; i < chunks; i++) {
            const chunk = file.slice(start, end);
            const headers = {
                'Content-Range': `bytes ${start}-${end - 1}/${file.size}`,
            };

            try {
                response = await axios.put(url, chunk, {
                    headers, maxRedirects: 0, validateStatus: (status) => {
                        // Aceptar el código de redirección permanente (308)
                        return status === 308 || (status >= 200 && status < 300);
                    },
                });
                console.log(`Chunk ${i + 1} cargado correctamente.`);
                console.log("Headers", response.headers);
                // El servidor debe responder con un encabezado 'Range' que contenga la información
                // para el siguiente fragmento.

                if (response.status === 308) {
                    const nextRange = response.headers['range'];
                    const [, nextStart, nextEnd] = nextRange.match(/(\d+)-(\d+)/);
                    start = parseInt(nextEnd) + 1;
                    end = Math.min(start + chunkSize, file.size);
                    console.log("Start ", start, "End", end);
                    console.log("Esto queda en nextStart", nextStart, "nextEnd", nextEnd)
                } else if (response.status === 200) {
                    // Si no se proporciona un encabezado Range en la respuesta, maneja el error o
                    // el comportamiento de reintentar según sea necesario.
                    setSnackBarInfo({
                        message: "Archivo cargado correctamente",
                        severity: "success",
                        open: true,
                    });
                    setIsLoading(false);
                    setModal({ isOpen: false })
                    break;
                }
            } catch (error) {
                setSnackBarInfo({
                    message: "Ocurrio un error al cargar el archivo",
                    severity: "error",
                    open: true,
                });

                console.error(`Error al cargar el chunk ${i + 1}:`, error);
            }
        }
    };


    const getSessionUri = async (filename, size) => {
        const body = {
            service: "SBMURIGet",
            params: {
                documentId: xgridRowSelected.row.id,
                merchantId: credentials.merchantId,
                filename: filename,
                fileSize: size
            }
        }

        const response = await RequestServiceGet(body, credentials);
        return response[0];
    }


    return (
        <Grid container>
            <Grid item md={12} lg={12}>
                <Grid container spacing={2} display={"flex"} flexDirection={"column"}>
                    <Grid item lg={6}>
                        <div {...getRootProps({ className: 'dropzone' })} className={classes.dropZone}>
                            <input {...getInputProps()} />
                            <img src={IconUpload} alt="icon-upload" />
                            <p>Arrastra el archivo o <span style={{ textDecoration: "underline", cursor: "pointer" }}> buscalo </span></p>
                        </div>
                    </Grid>
                    <Grid item lg={6} display={"flex"} alignItems={"center"}>
                        {files.length ? files : <span> 0 archivos subidos</span>}
                        {errorFile ? <span>{errorFile}</span> : null}
                        {isLoading && <CircularIndeterminate />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}