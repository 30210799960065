import axiosPost from "../axiosPost";

export const horarioRangoUpd = async ({
    gateHorarioRangoId,
    gateHorarioRangoDia,
    gateHorarioRangoEntrada,
    gateHorarioRangoSalida,
    gateHorarioRangoVersion
}, credentials) => {
    const body = {
        service: "GateHorarioRangoUpd",
        params: {
            gateHorarioRangoId,
            gateHorarioRangoDia,
            gateHorarioRangoEntrada,
            gateHorarioRangoSalida,
            gateHorarioRangoVersion
        },
    };

    return await axiosPost(body, credentials);
};
