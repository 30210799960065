import React from 'react';
import styles from './index.module.scss';

function DoubleTooltip({ firstTooltipText, children, serviceAuthorize }) {

    return (

        <div className={styles['double-tooltip-container']}>
            <div className={`${styles.tooltip} ${styles['first-tooltip']}`}>
                {firstTooltipText}
            </div>

            {children}
        </div>


    );
}



export default DoubleTooltip;




