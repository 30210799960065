import React from "react";
import FormActions from "../FormAction/FormActions";
import { RequestServiceActions } from "services/services-mia/RequestServiceActions";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalData, snackbarData, userCredentials } from "recoilState/GlobalState";
import { ACTIONS } from "consts/Actions";

export default function InsertModal({ updateList }) {
    const credentials = useRecoilValue(userCredentials);
    const setModalDetails = useSetRecoilState(modalData)
    const setSnackbarInfo = useSetRecoilState(snackbarData)
    const handleSubmit = async (values) => {
        try {
            const body = {
                service: "SBMDocumentoIns",
                params: {
                    SBMTipoDocumentoId: values.tipoDocumento,
                    SBMDocumentoFecha: values.fechaDocumento,
                    SBMDocumentoNombre: values.nombreDocumento,
                    SBMDocumentoDescripcion: values.descripcion,
                    SBMDocumentoRequiereFirma: values.requiereFirma === true ? "Y" : "N",
                    SBMDocumentoFirmantesExternos: values.cantFirmantesExternos,
                    SBMDocumentoFirmantesInternos: values.cantFirmantesInternos
                }

            }
            await RequestServiceActions(body, credentials, ACTIONS.INSERT, setSnackbarInfo);
            setModalDetails({ isOpen: false });
            await updateList();
        } catch (e) {

        }
    }
    return (
        <section>
            <main>
                <FormActions handleSubmit={handleSubmit} initialValues={{
                    requiereFirma: false,
                    tipoDocumento: 1,
                    nombreDocumento: "",
                    descripcion: "",
                    fechaDocumento: new Date(),
                }} />
            </main>
        </section>
    );
}