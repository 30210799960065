import React, { useState, useEffect, useCallback } from "react";

// recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userCredentials, routeInfo } from "recoilState/GlobalState";

//Services
import { zonaGet } from "services/services-mia/miakey";
import Modal from "components/UI/Modal/Modal";
import { useService } from "components/route/ServiceRoute";

// utils
import XGrid from "components/UI/Grid/XGrid";
import { HeaderButton, Buttons } from "components";
import { Add, Cached, DeleteOutline, EditOutlined } from "@mui/icons-material";

import { DeleteModal, InsertModal, UpdateModal } from "./Actions";

// consts
import { sucursales as titles } from "consts/titulos-de-tablas";
import { ACTIONS } from "consts/Actions";
import { Routes } from "consts/Routes-App";

// styles
import s from "styles/pages/Pages.module.scss"

export default function Zonas() {
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })

    const { service } = useService();

    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo);

    const updateList = useCallback(async () => {
        setIsLoading(true);
        setRows([]);
        const { result } = await zonaGet({}, credentials);
        setRows(result.map((item) => {
            const {
                gateZonaEsDefault,
                gateZonaId,
                gateZonaNombre,
                gateZonaUFH,
                gateZonaUID,
                gateZonaVersion,
                merchantId,
                merchantNombre,
                merchantSucursalId,
                merchantSucursalNombre,
            } = item;

            return {
                id: gateZonaId,
                gateZonaEsDefault,
                gateZonaId,
                gateZonaNombre,
                gateZonaUFH,
                gateZonaUID,
                gateZonaVersion,
                merchantId,
                merchantNombre,
                merchantSucursalId,
                merchantSucursalNombre,
                [titles[0]]: gateZonaNombre,
                [titles[1]]: merchantNombre,
                [titles[2]]: gateZonaEsDefault === "Y" ? "Sí" : "No",
            }
        }));
        setIsLoading(false);
    }, [credentials]);

    useEffect(() => {
        setRoute(Routes.zonas)
    }, [setRoute])

    useEffect(() => {
        updateList()
    }, [service, updateList])

    const handleClick = ({ procede, title }) => {
        setModalDetails({ title, procede, isOpen: true });
    };

    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };

    return (
        <main className={s.main}>
            <Modal onClose={closeModal} open={modalDetails.isOpen} title={modalDetails.title}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        zona={rowSelected.row}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DELETE && (
                    <DeleteModal
                        zona={rowSelected.row}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s.actions}>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={updateList}
                        disabled={rowSelected.row.id}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Agregar Zona"}
                        handleClick={handleClick}
                        data={{
                            title: "Agregar Zona",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id || !service.servicioPermiso === 'E'}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Editar Zona"}
                        handleClick={handleClick}
                        data={{
                            title: "Editar Zona",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!rowSelected.row.id || !service.servicioPermiso === 'E'}
                    >
                        <EditOutlined />
                    </HeaderButton>

                    <HeaderButton
                        text={"Eliminar Zona"}
                        handleClick={handleClick}
                        data={{
                            title: "Eliminar Zona",
                            procede: ACTIONS.DELETE,
                        }}
                        disabled={!rowSelected.row.id || !service.servicioPermiso === 'E'}
                    >
                        <DeleteOutline />
                    </HeaderButton>
                </Buttons>
            </div>
            <div className={s.container}>
                <XGrid
                    rows={rows}
                    titles={titles}
                    loading={isLoading}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    );
}
