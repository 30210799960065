export const sucursales = ["Nombre"];

export const accesos = [
    "Nombre",
    "Zona",
    "Provincia",
    "Localidad",
    "Calle",
    "Número",
    "Piso",
    "Unidad",
];

export const documentType = ["Nombre del Tipo de Documento", "Descripción"];

export const horarios = ["Nombre", "Descripción"];

export const rangos = ["Día", "Entrada", "Salida"];

export const kioscos = [
    "Zona",
    "Acceso",
    "Identificación",
    "Marca",
    "Modelo",
    "Serie",
    "MAC",
    "Tipo",
    "Estado",
    "Último cambio",
];
export const kioscosDetalles = [
    "Zona",
    "Acceso",
    "Tipo",
    "Estado",
    "Fecha",
    "Requiere Entrada",
    "Requiere Salida",
];

export const eventos = [
    "Usuario",
    "Entrada/Salida",
    "Fecha y hora",
    "Zona",
    "Acceso",
];

export const administracion = [
    "Apellido y Nombre",
    "DNI",
    "Sexo",
    "Fecha de nacimiento",
    "Servicio",
    "Rol",
    "Fecha de solicitud",
    "Fecha de alta",
    "Estado",
];
export const usuariosXServicio = [
    {
        field: 'fullName',
        headerName: "Apellido y Nombre",
        flex: 1,
        minWidth: 180,
    },
    {
        field: 'document',
        headerName: "Documento",
        type: 'number',
        format: 'number',
        width: 120
    },
    {
        field: 'documentType',
        headerName: "Tipo",
        width: 70,
    },
    {
        field: 'gender',
        headerName: "Sexo",
        width: 70,
        align: 'center'
    },
    {
        field: 'birthDay',
        headerName: "Nacimiento",
        type: 'date',
        format: 'date',
    },
    {
        field: 'email',
        headerName: "Email",
        flex: 1
    },
    {
        field: 'rol',
        headerName: "Rol",
        minWidth: 120
    },
    {
        field: 'onboard',
        headerName: "Onboard",
        align: 'center'
    },
    {
        field: 'creationDate',
        headerName: "Alta",
        type: 'date',
        format: 'date',
    },
    {
        field: 'status',
        headerName: "Estado"
    },
    {
        field: 'statusDate',
        headerName: "Fecha",
        type: 'date',
        format: 'date',
    },
];

export const dashboardAccesos = [
    "Zona",
    "Acceso",
    "Horario",
    "Descripción",
    "Estado",
    "Fecha",
];

export const dashboardAccesosEstados = ["Estado", "Fecha "];

export const document = [
    "Nombre",
    "Tipo",
    "Fecha de creación",
    "Descripción",
    "Requiere Firma",
    "Firmantes internos",
    "Firmantes externos",
    "Fecha Estado",
    "Estado",
];

export const participants = [
    "Nombre y apellido",
    "Tipo Documento",
    "Nro Documento",
    "Rol",
    "E-mail",
    "Fecha Estado",
    "Estado",
];

export const inviteParticipants = [
    "Nombre y apellido",
    "Tipo Documento",
    "Nro Documento",
];
