import { makeStyles } from "@mui/styles";

export const ModalStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:focus": {
      outline: "none",
    },
    paddingTop: theme.spacing(4),
    width: "100%",
    height: "100vh",
    marginBottom: "5%",
    [theme.breakpoints.up("xl")]: {
      paddingTop: theme.spacing(2),
      marginBottom: "1%",
    },
  },
  hr: {
    color: "#ADB8D0",
    opacity: "0.3",
    width: "100%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "1rem",
    boxShadow: theme.shadows[10],
    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "flex-start",
    outline: "none",
    padding: "2em 2em 1.5em 2em",
    position: "relative",
    textAlign: "center",
    width: "50%",
  },
  tableInModal: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "1rem",
    boxShadow: theme.shadows[10],
    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "flex-start",
    outline: "none",
    padding: "2em 2em 1.5em 2em",
    position: "relative",

    width: "80%",
  },
  title: {
    display: "flex",
    color: "#06132f",
    fontWeight: "200",
    fontSize: "2rem!important",
    marginBottom: "1.3rem",
  },
  closeButton: {
    cursor: "pointer",
    fontSize: "1em",
    position: "absolute",
    right: "2em",
    top: "1em",
  },
}));