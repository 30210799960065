export const checkCredentials = () => {
    if (
        localStorage.getItem("hooliToken") &&
        localStorage.getItem("sessionId")
    ) {
        return {
            hooliToken: localStorage.getItem("hooliToken"),
            sessionId: localStorage.getItem("sessionId"),
            merchantId: localStorage.getItem("merchantId"),
            merchantRZ: localStorage.getItem("merchantRZ"),
            merchantNombre: localStorage.getItem("merchantNombre"),
            merchantCUIT: localStorage.getItem("merchantCUIT"),
        };
    }
    return null;
};

export const checkAuth = () => {
    return (
        localStorage.getItem("hooliToken") &&
        localStorage.getItem("sessionId")
    ) ? true : false;
};
