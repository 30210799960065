import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import classes from "./Mapa.module.scss";
import SearchBox from "./SearchBox";
import { FormButton } from "components";

const containerStyle = {
    width: "100%",
    height: "300px",
    marginBottom: "1rem",
};

const libraries = ["places"];
export default function ModalMapa({
    latitud,
    longitud,
    calle,
    numero,
    localidad,
    provincia,
    pais,
    onSetCoordenadas,
    closeModal = () => { } }) {
    const [direccion, setDireccion] = useState('')
    const [coordenadas, setCoordenadas] = useState({
        lat: latitud
            ? typeof latitud === "number"
                ? latitud
                : parseFloat(latitud)
            : -34.6036844,
        lng: longitud
            ? typeof longitud === "number"
                ? longitud
                : parseFloat(longitud)
            : -58.3815591,
    });

    useEffect(() => {
        setDireccion(`${calle ?? ''} ${numero ?? ''}${localidad ? `, ${localidad}` : ''}${provincia ? `, ${provincia}` : ''}${pais ? `, ${pais}` : ''}`)
    }, [calle, localidad, numero, pais, provincia])

    const handleClick = (event) => {
        event.preventDefault();
        onSetCoordenadas({
            latitud: coordenadas.lat,
            longitud: coordenadas.lng
        });

        closeModal();
    };

    return (
        <div className={classes.mapaModal}>
            <LoadScript
                id="second-google-map-script"
                googleMapsApiKey="AIzaSyA3CvTeFPuHo7ePjm5YOva66gR-pFWZvPc"
                libraries={libraries}
            >
                <SearchBox
                    coordenadas={coordenadas}
                    onSetCoordenadas={setCoordenadas}
                    direccion={direccion}
                />
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={coordenadas}
                    zoom={14}
                    onClick={(e) =>
                        setCoordenadas({
                            lat: e.latLng.lat(),
                            lng: e.latLng.lng(),
                        })
                    }
                >
                    <Marker position={coordenadas} />
                </GoogleMap>
                <FormButton
                    onClick={handleClick}
                    onSecondaryClick={closeModal}
                    newFormData={{ button: "Seleccionar coordenadas" }}
                />
            </LoadScript>
        </div>
    );
}
