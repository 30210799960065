import { Button, TextField } from "@mui/material";
import React from "react";
import { fileToBase64 } from "utils/fileToBase64";

export function FormikTextInput({ data, name, formik, disabled, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            placeholder={data[name].placeholder}
            margin="normal"
            type="text"
            disabled={disabled}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
        />
    );
}

export function FormikNumberInput({ data, name, formik, disabled, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            placeholder={data[name].placeholder}
            margin="normal"
            type="number"
            disabled={disabled}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
        />
    );
}

export function FormikSelectInput({
    data,
    name,
    formik,
    disabled,
    options,
    optionKey,
    optionValue,
    optionName,
    className,
}) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            margin="normal"
            select
            disabled={disabled}
            SelectProps={{ native: true }}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
        >
            {options.map((option, index) => {
                return (
                    <option key={option[optionKey] || index} value={option[optionValue]}>
                        {option[optionName]}
                    </option>
                );
            })}
        </TextField>
    );
}

export function FormikYesOrNoInput({
    data,
    name,
    formik,
    disabled,
    className,
}) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            margin="normal"
            select
            disabled={disabled}
            SelectProps={{ native: true }}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
        >
            <option value="Y">Si</option>
            <option value="N">No</option>
        </TextField>
    );
}

export function FormikDateInput({ data, name, formik, disabled, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            placeholder={data[name].placeholder}
            margin="normal"
            type="date"
            disabled={disabled}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
        />
    );
}

export function FormikTimeInput({ data, name, formik, disabled, className }) {
    return (
        <TextField
            label={data[name].label}
            name={name}
            placeholder={data[name].placeholder}
            margin="normal"
            type="time"
            disabled={disabled}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputLabelProps={{ shrink: true }}
            fullWidth={className ? false : true}
            className={className}
        />
    );
}

export function FormikFileInput({ data, name, formik, disabled, className }) {
    const handleFile = async (e) => {
        const file = e.target.files[0];
        const fileData = await fileToBase64(file);
        formik.setFieldValue(name + "File", fileData);
    };

    return (
        <Button
            variant="contained"
            component="label"
            margin="normal"
            disabled={disabled}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            fullWidth={className ? false : true}
            className={className}
        >
            {data[name].label}
            <input hidden type="file" name={name} onChange={handleFile} />
        </Button>
    );
}
