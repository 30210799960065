import axiosPost from "../axiosPost";

export const provinciaGet = async ({
  paisId = null
}, credentials) => {
  const body = {
    service: "ProvinciaGet",
    params: {
      paisId
    },
  };

  return await axiosPost(body, credentials);
};