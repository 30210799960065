import React, { useCallback, useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { lugarDeviceUpd } from "services/services-mia/miakey";
import { Grid } from "@mui/material";

const tipos = [
    { tipoCod: "E", tipoNombre: "Entrada" },
    { tipoCod: "S", tipoNombre: "Salida" },
    { tipoCod: "A", tipoNombre: "Ambos" },
];
const estados = [
    { estadoCod: "A", estadoNombre: "Alta" },
    { estadoCod: "B", estadoNombre: "Baja" },
    { estadoCod: "S", estadoNombre: "Suspendido" },
];

export default function UpdateModal({ updateList, kioscoDetail, closeModal, zonas, accesos }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [accesosFiltrado, setAccesosFiltrado] = useState([]);

    const setSelected = useSetRecoilState(rowSelected);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const formikRef = useRef();

    const getAccesos = useCallback((zonaId) => {
        if (!zonaId) return;
        setIsLoading(true);
        const result = accesos.filter((acceso) => (acceso.gateZonaId === zonaId))
        setAccesosFiltrado(result)
        selectAcceso(result[0].gateLugarId)
        setIsLoading(false);
    }, [accesos])

    useEffect(() => {
        getAccesos(zonas[0].gateZonaId);
    }, [getAccesos, zonas]);

    const selectZona = (zonaId) => {
        formikRef.current.setFieldValue("zona", zonaId)
    }

    const selectAcceso = (accesoId) => {
        formikRef.current.setFieldValue("acceso", accesoId)
    }

    const handleChangeZona = (event) => {
        const zonaId = event.target.value;
        selectZona(zonaId)
        getAccesos(zonaId);
    }

    const handleChangeAcceso = (event) => {
        const accesoId = event.target.value;
        selectAcceso(accesoId);
    }

    const handleSubmit = async ({
        fecha,
        tipo,
        estado,
        zona,
        acceso,
        requiereEntrada,
        requiereSalida,
    }) => {
        setInRequest(true);

        const response = await lugarDeviceUpd({
            gateLugarDeviceId: kioscoDetail.gateLugarDeviceId,
            gateLugarId: acceso,
            gateLugarDeviceFecha: fecha,
            gateLugarDeviceStatus: estado,
            gateLugarDeviceTipo: tipo,
            gateDeviceRequiereEntrada: requiereEntrada,
            gateDeviceRequiereSalida: requiereSalida,
            gateLugarDeviceVersion: kioscoDetail.gateLugarDeviceVersion
        }, credentials);

        const functions = {
            setSnackbarInfo,
            setIsOpen: closeModal,
            setSelected,
            updateList,
        };

        handleResponse(
            response,
            "Configuración modificada exitosamente",
            functions
        );

        setInRequest(false);
    };

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                fecha: kioscoDetail.gateLugarDeviceFecha ?? new Date().toISOString().substring(0, 10),
                tipo: kioscoDetail.gateLugarDeviceTipo ?? "E",
                estado: kioscoDetail.gateLugarDeviceStatus ?? "A",
                zona: kioscoDetail.gateZonaId ?? zonas[0].gateZonaId,
                acceso: kioscoDetail.gateLugarId ?? accesosFiltrado[0]?.gateLugarId ?? "",
                requiereEntrada: kioscoDetail.requiereEntrada ?? "N",
                requiereSalida: kioscoDetail.requiereSalida ?? "Y",
            }}
            validationSchema={
                yup.object({
                    fecha: yup.date(FORM_ERROR_MESSAGES.date)
                        .required(FORM_ERROR_MESSAGES.required),
                    estado: yup.string(FORM_ERROR_MESSAGES.text)
                        .oneOf(estados.map((estado) => (estado.estadoCod)),
                            { message: 'Elija un estado válido' })
                        .required(FORM_ERROR_MESSAGES.required),
                    zona: yup.number(FORM_ERROR_MESSAGES.number)
                        .when('estado', {
                            is: "A",
                            then: yup.number().required(FORM_ERROR_MESSAGES.required)
                        }),
                    acceso: yup.number(FORM_ERROR_MESSAGES.number)
                        .when('estado', {
                            is: "A",
                            then: yup.number().required(FORM_ERROR_MESSAGES.required)
                        }),
                    tipo: yup.string(FORM_ERROR_MESSAGES.text)
                        .when('estado', {
                            is: "A",
                            then: yup.string()
                                .oneOf(tipos.map((tipo) => (tipo.tipoCod)),
                                    { message: 'Elija un tipo válido' })
                                .required(FORM_ERROR_MESSAGES.required),
                        }),
                    requiereEntrada: yup.string(FORM_ERROR_MESSAGES.text)
                        .when('estado', {
                            is: "A",
                            then: yup.string()
                                .oneOf(["N", "Y"],
                                    { message: 'Valor inválido' })
                                .required(FORM_ERROR_MESSAGES.required),
                        }),
                    requiereSalida: yup.string(FORM_ERROR_MESSAGES.text)
                        .when('estado', {
                            is: "A",
                            then: yup.string()
                                .oneOf(["N", "Y"],
                                    { message: 'Valor inválido' })
                                .required(FORM_ERROR_MESSAGES.required),
                        }),
                })
            }
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <Grid container justifyContent={"center"}>
                    <Grid item md={6} lg={6}>
                        <FormikTextInput
                            name="fecha"
                            labelText={"Fecha"}
                            type="date"
                            placeholder="Ingrese una fecha"
                        />
                    </Grid>
                    <Grid item md={6} lg={6}>
                        <FormikSelectInput
                            name="estado"
                            labelText="Estado"
                        >
                            {estados.map(({ estadoCod, estadoNombre }) => (
                                <option
                                    key={estadoCod}
                                    data-id={estadoCod}
                                    value={estadoCod}
                                >
                                    {estadoNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                    </Grid>
                    {formikRef.current.values.estado === "A" &&
                        <>
                            <Grid item md={6} lg={6}>
                                <FormikSelectInput
                                    name="zona"
                                    labelText="Zona"
                                    onChange={handleChangeZona}
                                >
                                    {zonas.map(({ gateZonaId, gateZonaNombre }) => (
                                        <option
                                            key={gateZonaId}
                                            data-id={gateZonaId}
                                            value={gateZonaId}
                                        >
                                            {gateZonaNombre}
                                        </option>
                                    ))}
                                </FormikSelectInput>
                            </Grid>
                            <Grid item md={6} lg={6}>
                                {accesosFiltrado.length > 0
                                    ? <FormikSelectInput
                                        name="acceso"
                                        labelText="Acceso"
                                        onChange={handleChangeAcceso}
                                    >
                                        {accesosFiltrado.map(({ gateLugarId, gateLugarNombre }) => (
                                            <option
                                                key={gateLugarId}
                                                data-id={gateLugarId}
                                                value={gateLugarId}
                                            >
                                                {gateLugarNombre}
                                            </option>
                                        ))}
                                    </FormikSelectInput>
                                    : <FormikTextInput
                                        name="acceso"
                                        labelText={"Acceso"}
                                        defaultValue="No hay accesos disponibles"
                                        disable
                                    />

                                }
                                <FormikSelectInput
                                    name="acceso"
                                    labelText="Acceso"
                                    onChange={handleChangeAcceso}
                                >
                                    {accesosFiltrado.map(({ gateLugarId, gateLugarNombre }) => (
                                        <option
                                            key={gateLugarId}
                                            data-id={gateLugarId}
                                            value={gateLugarId}
                                        >
                                            {gateLugarNombre}
                                        </option>
                                    ))}
                                </FormikSelectInput>
                            </Grid>
                            <Grid item md={4} lg={4}>
                                <FormikSelectInput
                                    name="tipo"
                                    labelText="Tipo"
                                >
                                    {tipos.map(({ tipoCod, tipoNombre }) => (
                                        <option
                                            key={tipoCod}
                                            data-id={tipoCod}
                                            value={tipoCod}
                                        >
                                            {tipoNombre}
                                        </option>
                                    ))}
                                </FormikSelectInput>
                            </Grid>
                            <Grid item md={4} lg={4}>
                                <FormikSelectInput
                                    name="requiereEntrada"
                                    labelText="Requiere Entrada"
                                >
                                    <option key="Y" data-id="Y" value="Y"> Sí </option>
                                    <option key="N" data-id="N" value="N"> No </option>
                                </FormikSelectInput>
                            </Grid>
                            <Grid item md={4} lg={4}>
                                <FormikSelectInput
                                    name="requiereSalida"
                                    labelText="Requiere Salida"
                                >
                                    <option key="Y" data-id="Y" value="Y"> Sí </option>
                                    <option key="N" data-id="N" value="N"> No </option>
                                </FormikSelectInput>
                            </Grid>
                        </>}
                    <FormButton
                        inRequest={inRequest || isLoading}
                        newFormData={{ button: "Guardar cambios" }}
                        onCloseModal={closeModal}
                    />
                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </Grid>
            </Form>
        </Formik>
    );
}
